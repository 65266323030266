import { QuantityChangedFunction } from 'components/Cart/CartItems';
import Checkout from 'components/Cart/Checkout';
import Confirm from 'components/Cart/Confirm';
import Empty from 'components/Cart/Empty';
import Flex from 'components/ui/Flex';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { debounce } from 'lodash';
import { CartInput, CartItemSkuAddOn } from 'microshop-api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import {
    Valid,
    addChangeComment,
    addChangeSku,
    addressValidated,
    deliverySet,
    removeFromCart,
} from 'store/reducers/cartSlice';

const Cart = () => {
    const { loading, cart } = useAppSelector(({ cart }) => cart);
    const dispatch = useAppDispatch();
    const { options } = useAppSelector(({ user }) => ({
        options: user.userIdentity?.orderOptions,
    }));

    const CartView = { Cart: 0, Confirm: 1 };
    const [cartPageState, setCartPageState] = useState({ view: CartView.Cart, confirm: null });

    useEffect(() => {
        if (options?.deliveryAddresses && options?.deliveryAddresses?.length > 0) {
            dispatch(
                deliverySet({
                    name: 'addressId',
                    value: options.deliveryAddresses?.[0].id ?? '',
                }),
            );
            dispatch(addressValidated({ valid: Valid.Valid }));
        }
    }, [options, dispatch]);

    const confirmed = () => {
        setCartPageState({ ...cartPageState, view: CartView.Confirm });
    };

    const onChangeQuantity: QuantityChangedFunction = ({
        sku,
        qty,
        split,
        collectionId,
        addons,
        inputs,
        splitId,
        immediate,
    }) => {
        if (Number.isNaN(qty) || typeof sku !== 'string') return;

        if (immediate) {
            dispatchToServer(sku, qty, addons, collectionId, split, splitId, inputs);
        } else {
            debouncedHandleQtyChanged(sku, qty, addons, collectionId, split, splitId, inputs);
        }
    };

    const dispatchToServer = useCallback(
        (
            sku: string,
            quantity: number,
            addOns?: CartItemSkuAddOn[],
            collectionId?: number | null,
            split?: string | null,
            splitId?: number | undefined,
            inputs?: CartInput[],
        ) => dispatch(addChangeSku({ sku, collectionId, split, splitId, quantity, addOns, inputs })),
        [dispatch],
    );

    const debouncedHandleQtyChanged = useMemo(() => debounce(dispatchToServer, 600), [dispatchToServer]);

    const onSetComment = (skuPattern: string, comment: string) => {
        dispatch(addChangeComment({ skuPattern, comment }));
    };

    const onRemove = (sku: string) => {
        dispatch(removeFromCart({ sku }));
    };

    const isLoaded = !loading;
    const isEmpty = (cart?.quantity ?? 0) === 0;

    if (loading && isEmpty) {
        return <Skeleton height={420} className="p-3" />;
    }

    return (
        <Flex column className="pt-4 pb-2 px-3">
            {cartPageState.view === CartView.Cart && !isEmpty && (
                <Checkout
                    cart={cart}
                    stuck={true}
                    onChangeQuantity={onChangeQuantity}
                    onRemove={onRemove}
                    onSetComment={onSetComment}
                    onConfirm={confirmed}
                />
            )}
            {cartPageState.view === CartView.Confirm && <Confirm />}
            {isEmpty && isLoaded && cartPageState.view !== CartView.Confirm && <Empty />}
        </Flex>
    );
};

export default Cart;
