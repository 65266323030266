"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopUserToJSON = exports.ShopUserFromJSONTyped = exports.ShopUserFromJSON = exports.instanceOfShopUser = void 0;
var runtime_1 = require("../runtime");
var ShopUserType_1 = require("./ShopUserType");
/**
 * Check if a given object implements the ShopUser interface.
 */
function instanceOfShopUser(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfShopUser = instanceOfShopUser;
function ShopUserFromJSON(json) {
    return ShopUserFromJSONTyped(json, false);
}
exports.ShopUserFromJSON = ShopUserFromJSON;
function ShopUserFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'email': !(0, runtime_1.exists)(json, 'email') ? undefined : json['email'],
        'firstName': !(0, runtime_1.exists)(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !(0, runtime_1.exists)(json, 'lastName') ? undefined : json['lastName'],
        'phoneNumber': !(0, runtime_1.exists)(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'adressLine1': !(0, runtime_1.exists)(json, 'adressLine1') ? undefined : json['adressLine1'],
        'adressLine2': !(0, runtime_1.exists)(json, 'adressLine2') ? undefined : json['adressLine2'],
        'postalCode': !(0, runtime_1.exists)(json, 'postalCode') ? undefined : json['postalCode'],
        'city': !(0, runtime_1.exists)(json, 'city') ? undefined : json['city'],
        'country': !(0, runtime_1.exists)(json, 'country') ? undefined : json['country'],
        'role': !(0, runtime_1.exists)(json, 'role') ? undefined : (0, ShopUserType_1.ShopUserTypeFromJSON)(json['role']),
        'isAttestent': !(0, runtime_1.exists)(json, 'isAttestent') ? undefined : json['isAttestent'],
        'requiresAttestation': !(0, runtime_1.exists)(json, 'requiresAttestation') ? undefined : json['requiresAttestation'],
        'employeeNumber': !(0, runtime_1.exists)(json, 'employeeNumber') ? undefined : json['employeeNumber'],
        'orders': !(0, runtime_1.exists)(json, 'orders') ? undefined : json['orders'],
    };
}
exports.ShopUserFromJSONTyped = ShopUserFromJSONTyped;
function ShopUserToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'phoneNumber': value.phoneNumber,
        'adressLine1': value.adressLine1,
        'adressLine2': value.adressLine2,
        'postalCode': value.postalCode,
        'city': value.city,
        'country': value.country,
        'role': (0, ShopUserType_1.ShopUserTypeToJSON)(value.role),
        'isAttestent': value.isAttestent,
        'requiresAttestation': value.requiresAttestation,
        'employeeNumber': value.employeeNumber,
        'orders': value.orders,
    };
}
exports.ShopUserToJSON = ShopUserToJSON;
