import styled from 'styled-components/macro';
import { ReactNode } from 'react';
import Flex from 'components/ui/Flex';
import Text from 'components/ui/Text';
import { Link } from 'react-router-dom';
import UserCustomerCodeOptions from './ShopunitOptions';
import Button from 'components/ui/Button';
import { useTranslation } from 'react-i18next';
import useAppSelector from 'hooks/useAppSelector';
import { selectIsAttestant, selectIsShopManager, selectSelectedShopUnitInfo } from 'store/reducers/userSlice';

type UserCardProps = {
    profilePath: string;
    fullName: string | null;
    userName: string | null;
    logoutLabel: string;
    logoutPath: { pathname: string; state: { local: boolean } };
    children?: ReactNode;
    handleOnMenuItemClick: () => void;
};

export const UserCard = ({
    profilePath,
    fullName,
    userName,
    logoutPath,
    logoutLabel,
    handleOnMenuItemClick,
    children,
}: UserCardProps) => {
    const { t } = useTranslation();

    const isAttestant = useAppSelector(selectIsAttestant);
    const isShopManager = useAppSelector(selectIsShopManager);

    const menuItems: { label: string; route: string }[] = [
        {
            label: t('customerData'),
            route: '/customer',
        },
        ...(isAttestant ? [{ label: 'Attest', route: '/attestations' }] : []),
        {
            label: t('orders'),
            route: '/orders',
        },
        ...(isShopManager ? [{ label: t('users'), route: '/users' }] : []),
    ];

    return (
        <StyledUserCard>
            <UserCardContainer>
                <TopBar justify="between" align="center" className="text-nowrap">
                    <Link to={profilePath}>{fullName}</Link>
                    <UserCustomerCodeOptions />
                </TopBar>
                <Flex className="mt-1" column>
                    {menuItems?.map((menuItem) => (
                        <Link to={menuItem.route} key={menuItem.label} onClick={handleOnMenuItemClick}>
                            {menuItem?.label}
                        </Link>
                    ))}
                </Flex>
                <div>
                    <Divider className="mt-2 mb-2" />
                    <Flex column align="center">
                        <Text>{t('loggedInAs')}: </Text>
                        <Text>{userName}</Text>
                    </Flex>
                    <Divider className="mt-2 mb-2" />
                    <Flex justify="center">
                        <Link className="mt-2" to={logoutPath.pathname} state={logoutPath.state}>
                            <Button>{logoutLabel}</Button>
                        </Link>
                    </Flex>
                </div>
                {children}
            </UserCardContainer>
        </StyledUserCard>
    );
};

const StyledUserCard = styled.div`
    min-width: 300px;
    background: #ffffff;
    box-shadow: 0px 0px 4px 0px #00000040;
    top: 95%;
    right: 0;
    box-shadow: 0px 0px 24px 0 rgba(0, 0, 0, 0.16);
    position: absolute;
    padding: 24px;
    font-size: 12px;

    ${({ theme }) => theme.media.md} {
        min-width: 350px;
    }
`;

const UserCardContainer = styled.div``;

const TopBar = styled(Flex)`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.text};
`;

const Divider = styled.div`
    border-top: 1px solid rgb(233, 233, 233);
`;
