import { UserApi } from 'microshop-api';
import { config } from './api';

const userApi = new UserApi(config);

const user = {
    resetPassword: (email: string): Promise<boolean> =>
        userApi.userResetPasswordPOST({ resetPasswordModel: { email } }),
    updateProfile: (updateProfileModel: {
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
    }): Promise<boolean> =>
        userApi.userUpdateProfilePOST({
            updateProfileModel: { ...updateProfileModel },
        }),
};

export { user };
