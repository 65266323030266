import { useEffect, useState } from 'react';

export const useSimulateLoading = (trigger: any) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 300);
    }, [trigger]);

    return loading;
};
