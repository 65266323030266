"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopProductSizeDisplayTypeToJSON = exports.ShopProductSizeDisplayTypeFromJSONTyped = exports.ShopProductSizeDisplayTypeFromJSON = exports.ShopProductSizeDisplayType = void 0;
/**
 *
 * @export
 */
exports.ShopProductSizeDisplayType = {
    ManySizeAndQuantity: 0,
    OneSizePerProduct: 1
};
function ShopProductSizeDisplayTypeFromJSON(json) {
    return ShopProductSizeDisplayTypeFromJSONTyped(json, false);
}
exports.ShopProductSizeDisplayTypeFromJSON = ShopProductSizeDisplayTypeFromJSON;
function ShopProductSizeDisplayTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.ShopProductSizeDisplayTypeFromJSONTyped = ShopProductSizeDisplayTypeFromJSONTyped;
function ShopProductSizeDisplayTypeToJSON(value) {
    return value;
}
exports.ShopProductSizeDisplayTypeToJSON = ShopProductSizeDisplayTypeToJSON;
