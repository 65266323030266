import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CatalogNodeModel, FilterOutput, ListProduct, ListProductPageInfo, ShopAssortmentType } from 'microshop-api';
import services from 'services';
import { AppDispatch, RootState } from 'store';
import { KeyValueString } from 'types/KeyValuePair';

let GetProductsController: AbortController | null;
let GetFiltersController: AbortController | null;

export function getFilterParam(filters: ActiveFilter[]) {
    let filterJson = {};
    if (filters.length) {
        filterJson = filters.reduce((acc, filter) => {
            if (filter.values.length) {
                return {
                    ...acc,
                    [filter.type]: filter.values.map((f) => f.key),
                };
            }

            return acc;
        }, {});
    }

    return JSON.stringify(filterJson);
}

export const All_FilterKind = [
    'color',
    'brand',
    'gender',
    'certification',
    // 'designer',
    // 'theme',
    // 'retail',
    // 'sleeve',
    // 'material',
    // 'feature',
    // 'activity',
    // 'range',
    // 'category',
    // 'care',
    // 'neckline',
    // 'fit',
    // 'outlet',
];

export type FilterKindTuples = typeof All_FilterKind;
export type FilterKind = FilterKindTuples[number];
export type OrderBy = 'relevance' | 'number' | 'name' | 'new';

export type ActiveFilter = {
    type: FilterKind;
    values: KeyValueString[];
};

export type FilterToggleAction = {
    type: FilterKind;
    value: KeyValueString;
};

type SortName = 'relevance' | 'new' | 'name' | 'number' | 'priceDesc';
export type SortOption = {
    sort: SortName;
    visible: boolean;
};

export type AssortmentPageInfo = {
    hasNextPage?: boolean;
    hasPreviousPage?: boolean;
    totalItems?: number;
    totalPages?: number;
    currentPage?: number;
    pageSize?: number;
};

type AssortmentState = {
    products: ListProduct[];
    loading: boolean;
    showBrand: boolean;
    currentPathName: string;

    categories: CategoryUrlMaps;
    categoriesLoading: boolean;
    categoriesRaw: CatalogNodeModel[];

    assortmentPageInfo: AssortmentPageInfo;

    filters: FilterOutput[];
    filtersActive: ActiveFilter[];
    filtersLoading: boolean;
    filtersShow: boolean;

    selectedOrderBy?: OrderBy;
};

const initialState: AssortmentState = {
    products: [],
    loading: false,
    showBrand: true,
    currentPathName: '',

    assortmentPageInfo: {
        currentPage: 1,
    },

    categories: {},
    categoriesRaw: [],
    categoriesLoading: false,

    filters: [],
    filtersActive: [],
    filtersLoading: false,
    filtersShow: true,
};

const assortmentSlice = createSlice({
    name: 'assortment',
    initialState,
    reducers: {
        filterToggled(state, action: PayloadAction<FilterToggleAction>) {
            const { type, value } = action.payload;
            const filter = state.filtersActive.find((f) => f.type === type);

            if (!filter) {
                state.filtersActive = [...state.filtersActive, { type, values: [value] }];
            } else if (filter?.values.find((v) => v.key === value.key)) {
                state.filtersActive = [
                    ...state.filtersActive.filter((f) => f.type !== type),
                    { ...filter, values: filter.values.filter((f) => f.key !== value.key) },
                ];
            } else {
                state.filtersActive = [
                    ...state.filtersActive.filter((f) => f.type !== type),
                    { ...filter, values: [...filter.values, value] },
                ];
            }
        },
        showFilterToggled(state, action: PayloadAction<boolean | undefined>) {
            state.filtersShow = action.payload !== undefined ? action.payload : !state.filtersShow;
        },
        filtersCleared(state) {
            state.filtersActive = [];
        },
        selectedOrderByChanged(state, action: PayloadAction<OrderBy>) {
            state.selectedOrderBy = action.payload;
        },
        setAssortmentLocation(state, action: PayloadAction<string>) {
            state.currentPathName = action.payload.toLowerCase();
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadProducts.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(loadProducts.fulfilled, (state, action: PayloadAction<ListProductPageInfo>) => {
            const {
                items,
                hasNextPage,
                currentPage,
                hasPreviousPage,
                pageSize,
                totalItems,
                totalPages,
            } = action.payload;

            state.products = items ?? [];
            state.assortmentPageInfo = {
                hasNextPage,
                currentPage,
                hasPreviousPage,
                pageSize,
                totalItems,
                totalPages,
            };
            state.loading = false;
        });
        builder.addCase(getAllCategories.pending, (state) => {
            state.categoriesLoading = true;
        });
        builder.addCase(getAllCategories.fulfilled, (state, action) => {
            state.categories = { ...action.payload.categories };
            state.categoriesRaw = [...action.payload.categoriesRaw];
            state.categoriesLoading = false;
        });
        builder.addCase(getFilters.pending, (state) => {
            state.filtersLoading = true;
        });
        builder.addCase(getFilters.fulfilled, (state, action) => {
            const filters = action.payload.reduce((acc, filter) => {
                return [...acc, filter];
            }, [] as FilterOutput[]);

            state.filters = filters;
            state.filtersLoading = false;
        });
    },
});

export const loadProducts = createAsyncThunk<
    ListProductPageInfo,
    { page?: number; all?: boolean; featured?: boolean } | undefined,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>('assortment/load_products', async (settings, { dispatch, getState, rejectWithValue }) => {
    if (GetProductsController?.signal && !GetProductsController?.signal.aborted) GetProductsController.abort();

    const state = getState().assortment;
    const assortmentType = getState().shop?.shop?.assortmentType;

    const getAllCategoryCodes = (category: CategoryUrlMap, categories: CategoryUrlMaps): string[] => {
        const nestedCategories = category.children.map((child) => categories[child.path]);
        const nestedCategoryCodes = nestedCategories.flatMap((nestedCategory) =>
            getAllCategoryCodes(nestedCategory, categories),
        );
        return [category.code, ...nestedCategoryCodes];
    };
    const selectedCategory = state.categories[state.currentPathName.toLowerCase()]
        ? [state.categories[state.currentPathName.toLowerCase()].code]
        : undefined;

    const selectedCategoryCodes =
        selectedCategory && assortmentType === ShopAssortmentType.Custom
            ? getAllCategoryCodes(state.categories[state.currentPathName.toLowerCase()], state.categories)
            : selectedCategory;
    try {
        GetProductsController = new AbortController();

        const categories = settings?.all
            ? state.categoriesRaw.length > 0
                ? [
                      ...state.categoriesRaw.map((a) => a.code).filter((code): code is string => !!code),
                      ...(state.categoriesRaw[0]?.children
                          ?.slice(0, 3)
                          .flatMap((child) => child.code)
                          .filter((code): code is string => !!code) || []),
                  ]
                : undefined
            : selectedCategoryCodes;

        const response = await services.assortment.getProducts(
            {
                orderBy: settings?.featured ? 'featured' : undefined,
                selectedFilters: getFilterParam(state.filtersActive),
                categories: categories,
                page: settings?.page ?? state.assortmentPageInfo.currentPage,
            },
            GetProductsController.signal,
        );

        return response;
    } catch (e) {
        return rejectWithValue({ error: 'products_not_found' });
    }
});

export const loadAssortmentLocation = createAsyncThunk<
    void,
    string,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>('assortment/set_assortment_location', async (url, { dispatch, getState, rejectWithValue }) => {
    dispatch(setAssortmentLocation(url));
});

export const changeOrderBy = createAsyncThunk<
    void,
    OrderBy,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>('assortment/changeOrderBy', async (orderBy, { dispatch }) => {
    dispatch(assortmentSlice.actions.selectedOrderByChanged(orderBy));

    dispatch(loadProducts({ page: 1 }));
});

export const getFilters = createAsyncThunk<
    FilterOutput[],
    void,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>('assortment/getFilters', async (_, { getState, dispatch, rejectWithValue }) => {
    if (GetFiltersController?.signal && !GetFiltersController?.signal.aborted) GetFiltersController.abort();

    const state = getState().assortment;
    const selectedCategory = state.categories[state.currentPathName.toLowerCase()]
        ? [state.categories[state.currentPathName.toLowerCase()].code]
        : undefined;
    const filters = getState().shop.shop?.assortmentFilters;

    try {
        GetFiltersController = new AbortController();

        const response = await services.assortment.getFilters(
            {
                assortmentIds: selectedCategory,
                filters: filters,
            },
            GetFiltersController.signal,
        );

        return response;
    } catch (e) {
        return rejectWithValue({ error: 'filters_not_found' });
    }
});

export const toggleFilter = createAsyncThunk<
    void,
    FilterToggleAction,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>('assortment/toggleFilter', async (filter, { dispatch }) => {
    dispatch(assortmentSlice.actions.filterToggled(filter));
    dispatch(loadProducts({ page: 1 }));
});

export const clearFilters = createAsyncThunk<
    void,
    void,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>('assortment/clearFilters', async (_, { dispatch }) => {
    dispatch(assortmentSlice.actions.filtersCleared());
    dispatch(loadProducts({ page: 1 }));
});

export type CategoryUrlMap = {
    name?: string | null;
    code: string;
    children: { name: string; path: string }[];
    parentCode: string;
    parentSlug: string;
};

type CategoryUrlMaps = { [key: string]: CategoryUrlMap };

export const getAllCategories = createAsyncThunk<
    { categories: CategoryUrlMaps; categoriesRaw: CatalogNodeModel[] },
    void,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>('assortment/getAllCategories', async (_, { getState, dispatch }) => {
    const response = await services.assortment.getCategories({});
    const rootCode = response?.[0].parent ?? 'root';
    const flatCatalogStructure = flattenCategories([{ children: response, code: rootCode }]).flatCategories;
    return { categories: flatCatalogStructure, categoriesRaw: response };
});

function flattenCategories(
    categories: CatalogNodeModel[],
    parentSlug = '',
    parentCategories = {},
): { flatCategories: CategoryUrlMaps; children: { name: string; path: string }[] } {
    return categories.reduce(
        (acc: any, category: any) => {
            if (!category.code) return acc;
            const key = `${parentSlug}${
                category?.assortmentSlug ? `/${category?.assortmentSlug || ''}`.toLowerCase() : ''
            }`;

            const { flatCategories, children } = flattenCategories(category.children ?? [], key);
            const returnAcc = {
                flatCategories: {
                    ...acc.flatCategories,
                    [key]: {
                        parentSlug: parentSlug,
                        parentCode: category.parent ?? 'root',
                        name: category.name || '',
                        code: category.code,
                        children: children,
                    },
                },
                children: [...acc.children, { name: category.name, path: key }],
            };

            if (category?.children?.length > 0) {
                return {
                    children: [...returnAcc.children],
                    flatCategories: { ...flatCategories, ...returnAcc.flatCategories },
                };
            }

            return returnAcc;
        },
        { flatCategories: parentCategories, children: [] },
    );
}

const selectCategories = (state: RootState) => state.assortment.categories;
const selectCurrentPathname = (state: RootState) => state.assortment.currentPathName;
const selectActiveFilters = (state: RootState) => state.assortment.filtersActive;
const selectFilters = (state: RootState) => state.assortment.filters;
export const selectCompleteActiveFilters = createSelector(
    selectFilters,
    selectActiveFilters,
    (filters, activeFilters) => {
        return activeFilters.map(({ type, values }) => {
            const types = filters?.flatMap((filter) => filter.filter).filter((filter) => filter?.fieldName === type);
            return {
                type: type,
                values: values.map((val) => {
                    const type = types.find((t) => t?.key === val.key);
                    return {
                        ...val,
                        value: type?.name ?? '',
                    };
                }),
            };
        });
    },
);

export const selectFlattenCompleteActiveFilters = createSelector(selectCompleteActiveFilters, (activeFilters) => {
    const flattenFilters = activeFilters.reduce((acc, filter) => {
        return [...acc, ...filter.values.map((f) => ({ type: filter.type, value: f }))];
    }, new Array<FilterToggleAction>());

    return flattenFilters.length === 0 ? null : flattenFilters;
});

export const selectSelectedOrderBy = (state: RootState) => state.assortment.selectedOrderBy;

export const selectOrderBy = createSelector(
    selectSelectedOrderBy,
    (selectedOrderBy): OrderBy => {
        return selectedOrderBy ?? 'relevance';
    },
);

export const selectAssortmentPage = createSelector(
    selectCategories,
    selectCurrentPathname,
    (categories, pathname): CategoryUrlMap => {
        return categories[pathname.toLowerCase()];
    },
);

type BreadCrumb = Omit<CategoryUrlMap & { slug?: string }, 'children' | 'code' | 'parentCode' | 'parentSlug'>;

export const selectBreadCrumbs = (currentSlug: string) =>
    createSelector(selectCategories, selectCurrentPathname, function getParent(categories, slug): BreadCrumb[] {
        const currentCategory = categories[slug];
        if (
            currentCategory &&
            currentCategory?.parentSlug! &&
            currentCategory?.parentSlug.length > 0 &&
            categories[currentCategory.parentSlug]
        ) {
            return [
                ...getParent(categories, currentCategory.parentSlug),
                ...(currentSlug === slug ? [] : [{ name: currentCategory?.name, slug: slug } as BreadCrumb]),
            ];
        }

        return [...(currentSlug === slug ? [] : [{ name: currentCategory?.name, slug: slug } as BreadCrumb])];
    });

const selectProducts = (state: RootState) => state.assortment.products;

export const selectProductLinks = createSelector(
    selectProducts,
    (products): Record<string, string> => {
        const links: Record<string, string> = {};
        for (const product of products) {
            if (product.productNumber) {
                links[product.productNumber] = `/product/${product.slug}`;
            }
        }

        return links;
    },
);

export const { filterToggled, showFilterToggled, setAssortmentLocation } = assortmentSlice.actions;
export default assortmentSlice.reducer;
