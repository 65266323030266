import { ApplicationPaths, LoginActions, LogoutActions } from 'components/ApiAuthorization/ApiAuthorizationConstants';
import { Login } from 'components/ApiAuthorization/Login';
import { Logout } from 'components/ApiAuthorization/Logout';
import { Customer } from 'components/User/Customer';
import useAppSelector from 'hooks/useAppSelector';
import { Attestations } from 'pages/Attestations';
import Cart from 'pages/Cart';
import Home from 'pages/Home';
import { Orders } from 'pages/Orders';
import Product from 'pages/Product';
import Products from 'pages/Products';
import { Users } from 'pages/Users';
import { Route, Routes } from 'react-router-dom';
import { selectUserInfo } from 'store/reducers/shopSlice';

function ShopRoutes() {
    const isAuthenticated = useAppSelector(selectUserInfo)?.isAuthenticated;
    return (
        <Routes>
            <Route path="/">
                <Route path="/" element={<Home />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/products/:category" element={<Products />} />
                <Route path="/product/:category?/:productNumber" element={<Product />} />
                {isAuthenticated && (
                    <>
                        <Route path="/customer" element={<Customer />} />
                        <Route path="/attestations" element={<Attestations />} />
                        <Route path="/orders" element={<Orders />} />
                        <Route path="/users" element={<Users />} />
                    </>
                )}

                {/* <Route path={ApplicationPaths.ApiAuthorizationPrefix}>
                    <Route path={ApplicationPaths.Login} element={loginAction(LoginActions.Login)} />
                    <Route path={ApplicationPaths.LoginFailed} element={loginAction(LoginActions.LoginFailed)} />
                    <Route path={ApplicationPaths.LoginCallback} element={loginAction(LoginActions.LoginCallback)} />
                    <Route path={ApplicationPaths.Profile} element={loginAction(LoginActions.Profile)} />
                    <Route path={ApplicationPaths.Register} element={loginAction(LoginActions.Register)} />
                    <Route path={ApplicationPaths.LogOut} element={logoutAction(LogoutActions.Logout)} />
                    <Route
                        path={ApplicationPaths.LogOutCallback}
                        element={logoutAction(LogoutActions.LogoutCallback)}
                    />
                    <Route path={ApplicationPaths.LoggedOut} element={logoutAction(LogoutActions.LoggedOut)} />
                </Route> */}
                <Route path="/404" element={<></>} />
                <Route path="/*" element={<Products />} />
            </Route>
        </Routes>
    );
}

// function loginAction(name: string) {
//     return <Login action={name}></Login>;
// }

// function logoutAction(name: string) {
//     return <Logout action={name}></Logout>;
// }

export default ShopRoutes;
