"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckoutErrorToJSON = exports.CheckoutErrorFromJSONTyped = exports.CheckoutErrorFromJSON = exports.CheckoutError = void 0;
/**
 *
 * @export
 */
exports.CheckoutError = {
    InvalidBudget: 0,
    ServerError: 1
};
function CheckoutErrorFromJSON(json) {
    return CheckoutErrorFromJSONTyped(json, false);
}
exports.CheckoutErrorFromJSON = CheckoutErrorFromJSON;
function CheckoutErrorFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.CheckoutErrorFromJSONTyped = CheckoutErrorFromJSONTyped;
function CheckoutErrorToJSON(value) {
    return value;
}
exports.CheckoutErrorToJSON = CheckoutErrorToJSON;
