import { faShippingFast, faTags } from '@fortawesome/pro-solid-svg-icons';
import Button from 'components/ui/Button';
import Checkbox from 'components/ui/Checkbox';
import Flex from 'components/ui/Flex';
import Input from 'components/ui/Input';
import Radio from 'components/ui/Radio';
import Select from 'components/ui/Select';
import Text from 'components/ui/Text';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { CartSummary, CustomDeliveryAddressSettings, ShopDeliveryAddress } from 'microshop-api';
import { useEffect, useMemo, useState } from 'react';
import { FormLabel } from 'react-bootstrap';
import FormGroup from 'react-bootstrap/esm/FormGroup';
import Form from 'react-bootstrap/Form';
import FormCheck from 'react-bootstrap/FormCheck';
import FormControl from 'react-bootstrap/FormControl';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { Col, Row } from 'reactstrap';
import {
    addressEdited,
    confirmB2BCheckout,
    confirmCheckout,
    currentDeliveryAddressSet,
    deliveryAddressEditingSet,
    selectCustomDeliveryAddressSettingsActive,
    selectDeliveryAddressEditing,
    selectedB2BDeliveryAddress,
    selectInputFIeldsDisabled,
    selectSelectedDeliveryAddress,
    setStandardAddressProperty,
    standardAddressSet,
    trySetInitialAddress,
    Valid,
} from 'store/reducers/cartSlice';
import styled from 'styled-components';
import Agreement from './Agreement';
import AsideSection from './AsideSection';
import CartItems, { QuantityChangedFunction } from './CartItems';
import CheckoutButton from './CheckoutButton';
import Delivery from './Delivery';
import DeliveryInfo from './DeliveryInfo';
import Total from './Total';

export type Option<T = unknown> = { value: T; label: string };
export declare type SingleValue<Option> = Option | null;

type CheckoutProps = {
    onConfirm: Function;
    stuck: boolean;
    onChangeQuantity: QuantityChangedFunction;
    onRemove: (sku: string) => void;
    onSetComment: (sku: string, comment: string) => void;
    cart: CartSummary;
};

const Checkout = ({ onConfirm, stuck, onChangeQuantity, onRemove, onSetComment, cart }: CheckoutProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { loadingOrder } = useAppSelector(({ cart }) => cart);
    const { loadingAddress, address } = useAppSelector(({ cart }) => cart.standardCheckout);
    const { delivery } = useAppSelector(({ cart }) => cart.b2bCheckout);
    const selectedDeliveryAddress = useAppSelector(selectedB2BDeliveryAddress);

    const { shop, info } = useAppSelector(({ shop }) => shop);
    const [validated, setValidated] = useState<boolean>(false);
    const isB2B = useAppSelector(({ shop }) => shop?.shop?.isB2BShop) ?? false;

    const showDeliveryAddresses = useAppSelector(selectDeliveryAddressEditing);
    const deliveryaddressesActive = useAppSelector(selectCustomDeliveryAddressSettingsActive);
    const currentDeliveryAddress = useAppSelector(selectSelectedDeliveryAddress);
    const disabled = useAppSelector(selectInputFIeldsDisabled);
    const deliveryAddressValid = showDeliveryAddresses === false && !!currentDeliveryAddress;

    const onCountryDelivery = (name: string, option: SingleValue<Option>) => {
        dispatch(setStandardAddressProperty({ key: name, value: (option?.value as string) ?? null }));
    };

    const countries = useMemo(() => {
        return info?.shippingCountries
            ? info?.shippingCountries.map((item) => ({ label: item ?? '', value: item ?? '' }))
            : ([] as { label: string; value: string }[]);
    }, [info?.shippingCountries]);

    const [selectedCountry, setSelectedCountry] = useState<Option>();

    useEffect(() => {
        if (delivery && delivery.country) {
            const c = countries.find((e) => e.value === delivery.country);
            if (c) {
                setSelectedCountry({ label: c.label, value: c.value });
                dispatch(setStandardAddressProperty({ key: 'country', value: (c?.value as string) ?? null }));
            }
        }
    }, [delivery, countries, isB2B, dispatch]);

    useEffect(() => {
        if (!address.serverLoaded && !loadingAddress && !isB2B) {
            dispatch(trySetInitialAddress());
            if (countries) {
                setSelectedCountry({ label: countries[0]?.label ?? '', value: countries[0]?.value ?? '' });
            }
        }
    }, [dispatch, address, loadingAddress, isB2B, countries]);

    const changeTextHandler = (event: any) => {
        dispatch(setStandardAddressProperty({ key: event.target.name, value: event.target.value }));
    };

    const changeCheckBoxHandler = (event: any) => {
        dispatch(setStandardAddressProperty({ key: event.target.name, value: event.target.checked }));
    };

    const submitHandler = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        const valid = form.checkValidity();
        setValidated(true);

        if (!valid) return;

        if (isB2B) {
            dispatch(confirmB2BCheckout({ b2bCheckout: { delivery: { ...delivery, ...selectedDeliveryAddress } } }));
        }

        if (!isB2B) {
            if ((address.address1?.length ?? 0) === 0) return;
            if ((address.firstName?.length ?? 0) === 0) return;
            if ((address.lastName?.length ?? 0) === 0) return;
            if ((address.postalCode?.length ?? 0) === 0) return;
            if ((address.city?.length ?? 0) === 0) return;
            if ((address.country?.length ?? 0) === 0) return;
            if ((address.email?.length ?? 0) === 0) return;
            if (!address.agreement) return;
            dispatch(confirmCheckout({ standardCheckout: address }));
        }

        onConfirm();
    };

    const changeAddressToDeliveryAddress = () => {
        dispatch(
            standardAddressSet({
                address1: currentDeliveryAddress?.address1 ?? '',
                address2: currentDeliveryAddress?.address2 ?? '',
                city: currentDeliveryAddress?.city ?? '',
                country: currentDeliveryAddress?.country ?? '',
                postalCode: currentDeliveryAddress?.postCode ?? '',
            }),
        );
    };

    return (
        <Row className="f3-400">
            <CartItemsColumn id="cartitems" $stuck={stuck} lg="8">
                <CartItems
                    onChangeQuantity={onChangeQuantity}
                    onRemove={onRemove}
                    cart={cart}
                    onSetComment={onSetComment}
                ></CartItems>
            </CartItemsColumn>
            <Col>
                {loadingAddress && <Skeleton height={400} width="100%"></Skeleton>}
                {!loadingAddress && (
                    <>
                        <SubHeader className="f3-700">{t('summary')}</SubHeader>
                        <Form noValidate validated={validated} onSubmit={submitHandler}>
                            <Aside>
                                {isB2B ? (
                                    <>
                                        <AsideSection
                                            header={t('shipment.address', 'Delivery address')}
                                            headerIcon={faShippingFast}
                                        >
                                            <Delivery onEditAddress={() => dispatch(addressEdited())} />
                                        </AsideSection>
                                        <AsideSection
                                            header={t('shipment.info', 'Shipment information')}
                                            headerIcon={faTags}
                                        >
                                            <DeliveryInfo />
                                        </AsideSection>
                                    </>
                                ) : (
                                    <>
                                        {deliveryaddressesActive ? (
                                            <AsideSection
                                                header={t('deliveryAddress', 'Delivery address')}
                                                validate
                                                valid={!deliveryAddressValid ? Valid.Unknown : Valid.Valid}
                                            >
                                                <div>
                                                    <StyledText
                                                        className="mb-2"
                                                        onClick={() => dispatch(deliveryAddressEditingSet(true))}
                                                    >
                                                        {t('choose_deliveryaddress', 'Choose delivery address')}
                                                    </StyledText>
                                                    {showDeliveryAddresses && (
                                                        <div className="mt-2 mb-2">
                                                            {address?.customDeliveryAddresses?.map((address, index) => (
                                                                <Flex
                                                                    align="center"
                                                                    className={'mb-3'}
                                                                    key={index}
                                                                    onClick={() =>
                                                                        dispatch(
                                                                            currentDeliveryAddressSet(
                                                                                `${address.name}${address.address1}`,
                                                                            ),
                                                                        )
                                                                    }
                                                                >
                                                                    <Radio
                                                                        onChange={() => null}
                                                                        defaultChecked={
                                                                            (currentDeliveryAddress?.name ===
                                                                                address.name &&
                                                                                currentDeliveryAddress?.address1 ===
                                                                                    address.address1) ??
                                                                            false
                                                                        }
                                                                    />
                                                                    <div>
                                                                        <Text fontSize={14} className="f3-700">
                                                                            {address.name}
                                                                        </Text>
                                                                        <Text fontSize={14}>{address.address1}</Text>
                                                                        {address.address2 && (
                                                                            <Text fontSize={14}>
                                                                                {address.address2}
                                                                            </Text>
                                                                        )}
                                                                        <Text fontSize={14}>{address.city}</Text>
                                                                        <Text fontSize={14}>{address.country}</Text>
                                                                    </div>
                                                                </Flex>
                                                            ))}
                                                            <Flex justify="end">
                                                                <Button
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        dispatch(deliveryAddressEditingSet(false));
                                                                        changeAddressToDeliveryAddress();
                                                                    }}
                                                                    role="button"
                                                                >
                                                                    {t('continue', 'Continue')}
                                                                </Button>
                                                            </Flex>
                                                        </div>
                                                    )}
                                                </div>
                                            </AsideSection>
                                        ) : null}

                                        <AsideSection>
                                            <Flex column>
                                                <Row className="mb-2">
                                                    <FormGroup as={Col}>
                                                        <StyledFormLabel className="f3-700">
                                                            {t('firstname')} *
                                                        </StyledFormLabel>
                                                        <FormControl
                                                            value={address.firstName ?? ''}
                                                            name="firstName"
                                                            onChange={changeTextHandler}
                                                            type="text"
                                                            id="firstName"
                                                            required
                                                        />
                                                    </FormGroup>
                                                    <FormGroup as={Col}>
                                                        <StyledFormLabel className="f3-700">
                                                            {t('lastname')} *
                                                        </StyledFormLabel>
                                                        <FormControl
                                                            value={address.lastName ?? ''}
                                                            name="lastName"
                                                            onChange={changeTextHandler}
                                                            type="text"
                                                            id="lastName"
                                                            required
                                                        />
                                                    </FormGroup>
                                                </Row>
                                                <Row className="mb-2">
                                                    <FormGroup as={Col}>
                                                        <StyledFormLabel className="f3-700">
                                                            {t('email')} *
                                                        </StyledFormLabel>
                                                        <FormControl
                                                            value={address.email ?? ''}
                                                            name="email"
                                                            disabled={address.emailLocked}
                                                            onChange={changeTextHandler}
                                                            type="email"
                                                            id="email"
                                                            required
                                                        />
                                                    </FormGroup>
                                                    <FormGroup as={Col}>
                                                        <StyledFormLabel className="f3-700">
                                                            {t('phone')}
                                                        </StyledFormLabel>
                                                        <FormControl
                                                            value={address.phone ?? ''}
                                                            name="phone"
                                                            onChange={changeTextHandler}
                                                            type="tel"
                                                            id="phone"
                                                            pattern="[0-9+\-\s]*"
                                                            inputMode="tel"
                                                        />
                                                    </FormGroup>
                                                </Row>
                                                <Row className="mb-2">
                                                    <FormGroup as={Col}>
                                                        <StyledFormLabel className="f3-700">
                                                            {t('address')} *
                                                        </StyledFormLabel>
                                                        <FormControl
                                                            value={address.address1 ?? ''}
                                                            name="address1"
                                                            onChange={changeTextHandler}
                                                            type="text"
                                                            id="address1"
                                                            required
                                                            disabled={disabled}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup as={Col}>
                                                        <StyledFormLabel className="f3-700">
                                                            {t('country')} *
                                                        </StyledFormLabel>
                                                        {!countries ||
                                                            (countries?.length === 0 && (
                                                                <FormControl
                                                                    value={address.country ?? ''}
                                                                    name="country"
                                                                    onChange={changeTextHandler}
                                                                    type="text"
                                                                    id="country"
                                                                    required
                                                                    disabled={disabled}
                                                                />
                                                            ))}
                                                        {countries.length > 1 && (
                                                            <Col md="6">
                                                                <Select
                                                                    id="country"
                                                                    name="country"
                                                                    key={`country`}
                                                                    placeholder={countries?.[0].label}
                                                                    required={true}
                                                                    value={selectedCountry}
                                                                    options={countries}
                                                                    isMulti={false}
                                                                    onChange={(option) => {
                                                                        setSelectedCountry(
                                                                            (option as SingleValue<Option>) ??
                                                                                undefined,
                                                                        );
                                                                        onCountryDelivery(
                                                                            'country',
                                                                            option as SingleValue<Option>,
                                                                        );
                                                                    }}
                                                                    isDisabled={disabled}
                                                                ></Select>
                                                            </Col>
                                                        )}
                                                        {countries.length === 1 && (
                                                            <Col md="6">
                                                                <Input
                                                                    readOnly
                                                                    name="country"
                                                                    defaultValue={countries[0].label ?? ''}
                                                                    type="text"
                                                                    id="country"
                                                                    disabled={disabled}
                                                                />
                                                            </Col>
                                                        )}
                                                    </FormGroup>
                                                </Row>
                                                <Row className="mb-2">
                                                    <FormGroup as={Col}>
                                                        <StyledFormLabel className="f3-700">
                                                            {t('postalcode')} *
                                                        </StyledFormLabel>
                                                        <FormControl
                                                            value={address.postalCode ?? ''}
                                                            name="postalCode"
                                                            onChange={changeTextHandler}
                                                            type="text"
                                                            id="postalCode"
                                                            required
                                                            disabled={disabled}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup as={Col}>
                                                        <StyledFormLabel className="f3-700">
                                                            {t('city')} *
                                                        </StyledFormLabel>
                                                        <FormControl
                                                            value={address.city ?? ''}
                                                            name="city"
                                                            onChange={changeTextHandler}
                                                            type="text"
                                                            id="city"
                                                            required
                                                            disabled={disabled}
                                                        />
                                                    </FormGroup>
                                                </Row>
                                                <Row className="mb-2">
                                                    <FormGroup className="mb-3">
                                                        <StyledFormLabel className="f3-700">
                                                            {t('comment')}
                                                        </StyledFormLabel>
                                                        <FormControl
                                                            className="form-control"
                                                            value={address.comment ?? ''}
                                                            name="comment"
                                                            onChange={changeTextHandler}
                                                            type="textarea"
                                                            id="comment"
                                                        />
                                                    </FormGroup>
                                                </Row>
                                                <Row className="mb-3">
                                                    <small className="form-text text-muted">
                                                        {t('agreement_short')}
                                                    </small>
                                                </Row>
                                            </Flex>
                                        </AsideSection>
                                    </>
                                )}

                                <AsideSection>
                                    <Total className="mb-4" />
                                    <Flex justify="center">
                                        <FormGroup className="mb-3">
                                            <Flex align="center">
                                                <Checkbox
                                                    name="agreement"
                                                    id="agreementCheck"
                                                    label={<Text className="me-1">{t('agreement_agree')}</Text>}
                                                    onChange={changeCheckBoxHandler}
                                                    checked={address.agreement}
                                                    required
                                                />
                                                <Agreement />
                                            </Flex>
                                        </FormGroup>
                                        {shop?.displayMarketingAgreement && (
                                            <FormGroup className="mb-3">
                                                <FormCheck
                                                    type="checkbox"
                                                    name="emailMarketing"
                                                    onChange={changeCheckBoxHandler}
                                                    checked={address.emailMarketing}
                                                    id="marketingCheck"
                                                    label={t('email_marketing')}
                                                />
                                            </FormGroup>
                                        )}
                                    </Flex>
                                    <CheckoutButton
                                        disabled={!address.agreement}
                                        text={t('place_order')}
                                        loading={loadingOrder}
                                        bgColor={'buy'}
                                    />
                                </AsideSection>
                            </Aside>
                        </Form>
                    </>
                )}
            </Col>
        </Row>
    );
};

const Aside = styled.aside`
    background: ${({ theme }) => theme.colors.fillWhite};

    h2 {
        margin-bottom: 0;
    }
`;

const SubHeader = styled.h4`
    font-size: 22px;
`;

const CartItemsColumn = styled(Col)<{ $stuck?: boolean }>`
    ${({ theme }) => theme.media.lg} {
        position: sticky;
        height: fit-content;
        transition: top 0.2s ease;
        top: 45px;

        ${({ $stuck }) =>
            $stuck &&
            `
        transition-duration: 0.5s;
      `}
    }
`;

const StyledFormLabel = styled(FormLabel)`
    font-size: 12px;
    margin-bottom: 0;
    padding-top: 3px;
    padding-bottom: 3px;
`;

const StyledText = styled(Text)`
    cursor: pointer;
    text-decoration: underline;
`;

export default Checkout;
