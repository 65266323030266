import { Configuration, HTTPHeaders } from 'microshop-api';

export const config: Configuration = new Configuration({
    basePath: '',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

export const setShopUnit = function (shopUnitId: string) {
    if (config.headers) {
        config.headers.ShopUnit = shopUnitId;
    }
};

export const updateShopUnitIdIfInvalid = function (validShopUnitIds: string[]) {
    console.log('validShopUnitIds', validShopUnitIds);

    const currentShopUnitId = localStorage.getItem('shopUnitId_' + window.location.hostname) ?? '';
    console.log(currentShopUnitId);
    if (validShopUnitIds.length === 0 && currentShopUnitId !== '') {
        localStorage.setItem('shopUnitId_' + window.location.hostname, '');
        setShopUnit('');
        console.log('newShopUnitId', '');
    } else if (!validShopUnitIds.includes(currentShopUnitId)) {
        const newShopUnitId = validShopUnitIds[0];
        localStorage.setItem('shopUnitId_' + window.location.hostname, newShopUnitId);
        setShopUnit(newShopUnitId);
        window.location.replace(window.location.origin + '/');
        console.log('newShopUnitId', newShopUnitId);
    }
};

export const setConfiguration = function ({ token }: { token: string }) {
    const bearer = `Bearer ${token}`;
    const headers = {
        ...config.headers,
        Authorization: bearer,
    } as HTTPHeaders;

    config.config = {
        basePath: config.basePath,
        fetchApi: config.fetchApi,
        middleware: config.middleware,
        queryParamsStringify: config.queryParamsStringify,
        username: config.username,
        password: config.password,
        apiKey: config.apiKey,
        accessToken: config.accessToken,
        headers: headers,
        credentials: config.credentials,
    } as Configuration;
};
