import useAppSelector from 'hooks/useAppSelector';
import { useTranslation } from 'react-i18next';
import { Container, Row } from 'reactstrap';
import styled from 'styled-components/macro';
import * as themeHelper from 'theme/functions';
import Flex from './Flex';

const Footer = ({
    contactEmail,
    contactPhone,
    contactName,
    company,
    companyText,
}: {
    contactEmail?: string | null;
    contactPhone?: string | null;
    contactName?: string | null;
    company?: string | null;
    companyText?: string | null;
}) => {
    const { t } = useTranslation();
    const { theme, legal, shopName } = useAppSelector(({ shop }) => ({
        theme: shop.theme,
        shopName: shop.shop?.name ?? '',
        legal: { privacyPolicyUrl: shop.shop?.privacyPolicyUrl, legalNoticeUrl: shop.shop?.legalNoticeUrl },
    }));

    return (
        <StyledFooterWrapper
            $color={theme?.footer}
            $light={themeHelper.isLight(theme)}
            className="w-100 font-small footer"
        >
            <Container className="text-center mb-4 pt-4">
                <Row className="mb-2 flex flex-column align-items-center">
                    {contactName != null && <h5 className="p-0 text-uppercase font-weight-bold">{contactName}</h5>}
                    {contactEmail != null && (
                        <span>
                            <i className="fa fa-envelope me-3" />
                            <a className="no-underline" href={'mailto:' + contactEmail}>
                                {contactEmail}
                            </a>
                        </span>
                    )}
                    {contactPhone != null && (
                        <span className="mb-4">
                            <i className="fa fa-phone me-3" />
                            {contactPhone}
                        </span>
                    )}
                    {companyText != null && <span className="w-50">{companyText}</span>}
                </Row>
                <div className="footer-copyright text-center pt-3">
                    <Container fluid>
                        <div>
                            Copyright &copy; {new Date().getFullYear()} {company ?? shopName}
                        </div>
                        {(legal?.legalNoticeUrl || legal?.privacyPolicyUrl) && (
                            <div className="text-right d-inline">
                                {legal?.legalNoticeUrl && (
                                    <a
                                        href={legal.legalNoticeUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-right py-3 px-3 font-weight-bold"
                                    >
                                        {t('legal_notice')}
                                    </a>
                                )}
                                {legal?.privacyPolicyUrl && (
                                    <a
                                        href={legal.privacyPolicyUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-right py-3 px-3 font-weight-bold"
                                    >
                                        {t('privacy_policy')}
                                    </a>
                                )}
                            </div>
                        )}
                    </Container>
                </div>
            </Container>
        </StyledFooterWrapper>
    );
};

const StyledFooterWrapper = styled(Flex)<{ $color: string; $light: boolean }>`
    ${({ $color }) => $color && `background-color: ${$color};`}
    ${({ $light }) => ($light ? `color: #000;` : `color: #fff;`)}

    a {
        color: inherit;
        text-decoration: underline;
    }
    a.no-underline {
        text-decoration: none;
    }
`;

export default Footer;
