import { faCircleInfo, faCoin, faTag } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/ui/Flex';
import Text from 'components/ui/Text';
import { ImageExtension, ProductStockSku } from 'microshop-api';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ProductPrice from './ProductPrice';
import StockInfo from './StockInfo';
import getImage, { ImageType } from 'utils/getImage';
import { CollectionVariation, StockOptions } from 'store/reducers/productSlice';

type SkuInfoProps = {
    sku: ProductStockSku;
    replacementPnr?: string;
    variation: CollectionVariation;
    largeArticle?: boolean;
    className?: string;
    single?: boolean;
    stockSettings: StockOptions;
};

const SkuInfo: FC<SkuInfoProps> = ({
    sku,
    variation,
    replacementPnr,
    largeArticle = true,
    className,
    single,
    stockSettings,
}) => {
    const { t } = useTranslation();

    const removeName = !sku.name || sku.name === '.' || sku.name === '0';
    const articleName = `${variation.color ? variation.color : ''}${!removeName ? ' / ' + sku.name : ''}`;

    return (
        <SkuInfoWrapper large={largeArticle} className={`${className ? className : ''}`}>
            {largeArticle ? (
                <Flex column className="">
                    <Flex className="f3-700 mb-2" align="center">
                        <FontAwesomeIcon icon={faTag} />
                        <Text small className="ms-3 text-capitalize">
                            {t('product.article_one', 'Article')}
                        </Text>
                    </Flex>
                    <Flex align="start" className="me-4 f3-400">
                        <ArticleImage src={getImage(variation.image, ImageType.ThumbNail, ImageExtension.Jpg)} />
                        <ArticleInfo className="ms-2 mb-2">
                            <div className="text-capitalize">{articleName}</div>
                            <div>{sku.sku}</div>
                            {sku.price && (
                                <div className="f3-700">
                                    <ProductPrice fontSize={14} compact price={sku.price}></ProductPrice>
                                </div>
                            )}
                        </ArticleInfo>
                    </Flex>
                    {/* {sku.stockLevel === StockLevel.OutOfStock && replacementPnr && (
                        <ReplacementProduct productNumber={replacementPnr} />
                    )} */}
                </Flex>
            ) : (
                !single && (
                    <>
                        <div>
                            <Flex className="f3-700 mb-2" align="center" justify="between">
                                <Flex align="center">
                                    <FontAwesomeIcon icon={faTag} />
                                    <Text small className="ms-3 text-capitalize">
                                        {t('article_one')}
                                    </Text>
                                </Flex>
                                <Text small className="text-capitalize">
                                    {articleName}
                                </Text>
                            </Flex>
                        </div>
                        {sku.price && (
                            <div>
                                <Flex className="f3-700 mb-2" align="center" justify="between">
                                    <Flex align="center">
                                        <FontAwesomeIcon icon={faCoin} />
                                        <Text small className="ms-3 text-capitalize">
                                            {t('price', 'Price')}
                                        </Text>
                                    </Flex>
                                    <ProductPrice compact price={sku.price}></ProductPrice>
                                </Flex>
                            </div>
                        )}
                    </>
                )
            )}
            {sku.stock && (
                <StockInfo
                    stock={sku.stock}
                    refillDate={sku.availableDate?.str}
                    className={largeArticle ? 'me-4' : 'w-100'}
                />
            )}
            {sku.ean && (
                <InfoCol column>
                    <Flex className="f3-700 mb-2" align="center">
                        <FontAwesomeIcon icon={faCircleInfo} />
                        <Text color="textLight" small className="ms-3 text-capitalize">
                            {t('general.data', 'Data')}
                        </Text>
                    </Flex>
                    <InfoTable>
                        <InfoTBody>
                            <InfoRow>
                                <InfoTd>EAN:</InfoTd>
                                <InfoTd>{sku.ean}</InfoTd>
                            </InfoRow>
                        </InfoTBody>
                    </InfoTable>
                </InfoCol>
            )}
        </SkuInfoWrapper>
    );
};

export default SkuInfo;

const SkuInfoWrapper = styled.div<{ large: boolean }>`
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;

    ${({ theme }) => theme.media.lg} {
        grid-template-columns: 1fr ${({ large }) => large && '1fr'};
    }

    ${({ theme }) => theme.media.xl} {
        grid-template-columns: 1fr ${({ large }) => large && '1fr 1fr'};
    }
`;

const ArticleImage = styled.img`
    width: 50px;
`;

export const InfoCol = styled(Flex)`
    max-width: 200px;
    color: ${({ theme }) => theme.colors.textLight};
`;

export const InfoTable = styled.table``;
export const InfoTBody = styled.tbody``;
export const InfoRow = styled.tr``;
export const InfoTd = styled.td<{ small?: boolean }>`
    font-size: 14px;
    padding: 0;

    ${({ small, theme }) =>
        small &&
        `
    font-size: 12px;
    color: ${theme.colors.textLight};
  `}

    :first-of-type {
        padding-right: 2em;
    }

    :last-of-type:not(:first-of-type) {
        text-align: right;
        font-size: 13px;
        font-weight: 700;
    }
`;

const ArticleInfo = styled.div`
    font-size: 14px;
`;
