import { faSearch, faTrash, faUserMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/ui/Button';
import Checkbox from 'components/ui/Checkbox';
import Flex from 'components/ui/Flex';
import Input, { Label } from 'components/ui/Input';
import Pagination from 'components/ui/Pagination';
import Radio from 'components/ui/Radio';
import Spinner from 'components/ui/Spinner';
import Table, { TableCell, TableRow } from 'components/ui/Table';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBreakPoints from 'hooks/useBreakPoints';
import { useSimulateLoading } from 'hooks/useSimulateLoading';
import debounce from 'lodash.debounce';
import { ShopUser, ShopUserType } from 'microshop-api';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    deleteShopUser,
    getShopUsers,
    searchUsersSet,
    selectIsShopManager,
    selectPagedShopUsers,
    selectPagedShopUsersLoading,
    selectSelectedShopUserDataUpdated,
    selectShopUsers,
    selectedShopUserDataUpdatedSet,
    selectedShopUserSet,
    selectsearchUsers,
    selectselectedShopUserData,
    upsertShopUser,
} from 'store/reducers/userSlice';
import styled from 'styled-components';
import Text from 'components/ui/Text';
import { alertQueued } from 'store/reducers/alertSLice';

export const Users = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const bp = useBreakPoints();
    const users = useAppSelector(selectShopUsers);
    const pagedShopUsers = useAppSelector(selectPagedShopUsers);
    const search = useAppSelector(selectsearchUsers);
    const selectedShopUserData = useAppSelector(selectselectedShopUserData);
    const userUpdated = useAppSelector(selectSelectedShopUserDataUpdated);
    const shopUsersLoading = useAppSelector(selectPagedShopUsersLoading);
    const isShopManager = useAppSelector(selectIsShopManager);
    const [uniqueKey, setUniqueKey] = useState(0);
    const loading = useSimulateLoading(selectedShopUserData?.email);

    const [isInvalid, setIsInvalid] = useState(false);

    const tableHeaders = [t('email'), t('name'), t('phone'), ''];

    useEffect(() => {
        dispatch(getShopUsers());
    }, []);

    useEffect(() => {
        dispatch(getShopUsers());
    }, [search]);

    const handleClick = (email: string) => {
        dispatch(selectedShopUserSet(email));
    };

    const handleRemove = (email: string) => {
        dispatch(
            alertQueued({
                cancelLabel: t('cancel'),
                okLabel: t('ok'),
                title: `${t('remove')} ${t('user')}`,
                onOk: () => dispatch(deleteShopUser(email)),
                onCancel: () => {},
            }),
        );
    };

    if (!isShopManager) {
        return null;
    }

    return (
        <div className="w-100 px-3 mx-0 my-2">
            <h2 className="mb-4">{t('users')}</h2>
            <Flex column={bp.lgBelow} className="" $gap={20}>
                <Left style={{ width: '100%' }}>
                    <Flex justify="between" align="center" $gap={20} /*style={{ maxWidth: '580px' }}*/>
                        <Input
                            defaultValue={search.searchPhrase}
                            type="text"
                            name="Search"
                            className="w-100"
                            icon={faSearch}
                            rightSidedIcon
                            onChange={debounce((e) => {
                                dispatch(searchUsersSet({ ...search, searchPhrase: e.target.value, page: 1 }));
                            }, 500)}
                            placeholder={t('searchUsersPlaceholder')}
                            paddingRight={'59px'}
                        />
                        <Button
                            type="button"
                            onClick={() => {
                                dispatch(selectedShopUserSet(null));
                            }}
                            bgColor="fillGray"
                        >
                            {t('createNewUser')}
                        </Button>
                    </Flex>
                    {!!users?.length ? (
                        <>
                            <Table
                                className="mx-2 my-4"
                                rowLayout="auto"
                                compact={false}
                                tintOddRows
                                headers={tableHeaders}
                                cols={tableHeaders.length}
                                rows={
                                    users
                                        ? getUserRows(
                                              users?.filter((user) => user.email !== undefined),
                                              handleClick,
                                              selectedShopUserData,
                                              handleRemove,
                                          )
                                        : []
                                }
                            />
                            <Pagination
                                page={pagedShopUsers?.currentPage!}
                                pages={pagedShopUsers?.totalPages!}
                                pageSize={pagedShopUsers?.pageSize!}
                                itemCount={pagedShopUsers?.items?.length!}
                                itemsTotal={pagedShopUsers?.totalItems!}
                                label={''}
                                setPage={(page: number) => {
                                    dispatch(searchUsersSet({ ...search, page: page }));
                                }}
                                compact={false}
                            />
                        </>
                    ) : (
                        <div /*style={{ maxWidth: '580px' }}*/ className="mt-5">
                            {!shopUsersLoading && (
                                <Flex column justify="center" align="center">
                                    <StyledFontAwesomeIcon icon={faUserMagnifyingGlass} size="3x" className="mb-2" />
                                    <Text fontSize={18} bold>
                                        {t('noMatches')}
                                    </Text>
                                    <Text>{t('noUsersFound')}</Text>
                                </Flex>
                            )}
                        </div>
                    )}
                </Left>
                {userUpdated && (
                    <Right key={`${selectedShopUserData?.email}${uniqueKey}`}>
                        {loading && (
                            <LoadingWrapper>
                                <Spinner center useFullHeight />
                            </LoadingWrapper>
                        )}

                        {!loading && (
                            <UserInfo>
                                <UserForm
                                    userUpdated={userUpdated}
                                    handleInvalid={(isInvalid: boolean) => setIsInvalid(isInvalid)}
                                    isInvalid={isInvalid}
                                />

                                <Flex $gap={12} justify="end">
                                    <Button
                                        type="button"
                                        onClick={() => {
                                            setUniqueKey(uniqueKey + 1);
                                            setIsInvalid(false);
                                            dispatch(selectedShopUserDataUpdatedSet(selectedShopUserData));
                                        }}
                                    >
                                        {t('cancel')}
                                    </Button>
                                    <Button
                                        type="button"
                                        onClick={() => {
                                            if (!userUpdated?.email) {
                                                setIsInvalid(true);
                                                return;
                                            }
                                            dispatch(upsertShopUser());
                                        }}
                                    >
                                        {t('save')}
                                    </Button>
                                </Flex>
                            </UserInfo>
                        )}
                    </Right>
                )}
            </Flex>
        </div>
    );
};

const getUserRows = (
    users: ShopUser[],
    handleClick: (email: string) => void,
    selectedShopUserData: ShopUser | undefined,
    handleRemove: (email: string) => void,
) => {
    return users?.map(
        (user): TableRow => {
            const fullName = `${user?.firstName} ${user?.lastName}`;
            const cells: TableCell[] = [
                user?.email ?? '',
                fullName ?? '',
                user?.phoneNumber ?? '',
                <FontAwesomeIcon
                    icon={faTrash}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleRemove(user?.email!);
                    }}
                />,
            ].map((el) => ({
                el,
            }));
            return {
                cells: cells,
                selected: user?.email === selectedShopUserData?.email,
                bgSelected: 'borderDark',
                onClick: () => {
                    if (!user?.email) return;
                    handleClick(user?.email);
                },
            };
        },
    );
};

const UserForm = ({
    userUpdated,
    handleInvalid,
    isInvalid,
}: {
    userUpdated: ShopUser;
    handleInvalid: (isInvalid: boolean) => void;
    isInvalid: boolean;
}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    return (
        <form>
            <div className="mb-3">
                <Flex $gap={22}>
                    <Input
                        className="mb-2 w-50"
                        type="text"
                        label={t('firstname')}
                        defaultValue={userUpdated?.firstName ?? ''}
                        name="firstName"
                        onChange={debounce((e) => {
                            dispatch(
                                selectedShopUserDataUpdatedSet({
                                    ...userUpdated,
                                    firstName: e.target.value,
                                }),
                            );
                        }, 500)}
                    />
                    <Input
                        className="mb-2 w-50"
                        type="text"
                        label={t('lastname')}
                        defaultValue={userUpdated?.lastName ?? ''}
                        name="lastName"
                        onChange={debounce((e) => {
                            dispatch(
                                selectedShopUserDataUpdatedSet({
                                    ...userUpdated,
                                    lastName: e.target.value,
                                }),
                            );
                        }, 500)}
                    />
                </Flex>
                <Flex $gap={22}>
                    <Input
                        className="mb-2 w-50"
                        type="text"
                        label={t('phone')}
                        defaultValue={userUpdated?.phoneNumber ?? ''}
                        name="phoneNumber"
                        onChange={debounce((e) => {
                            dispatch(
                                selectedShopUserDataUpdatedSet({
                                    ...userUpdated,
                                    phoneNumber: e.target.value,
                                }),
                            );
                        }, 500)}
                    />
                    <Input
                        className="mb-2 w-50"
                        type="text"
                        label={t('email')}
                        defaultValue={userUpdated?.email ?? ''}
                        errorText={isInvalid && !userUpdated?.email ? 'Email required' : ''}
                        name="email"
                        onChange={debounce((e) => {
                            handleInvalid(false);
                            dispatch(
                                selectedShopUserDataUpdatedSet({
                                    ...userUpdated,
                                    email: e.target.value,
                                }),
                            );
                        }, 500)}
                    />
                </Flex>

                <Flex $gap={22}>
                    <Input
                        className="mb-2 w-50"
                        type="text"
                        label={t('address')}
                        defaultValue={userUpdated?.adressLine1 ?? ''}
                        name="adressLine1"
                        onChange={debounce((e) => {
                            dispatch(
                                selectedShopUserDataUpdatedSet({
                                    ...userUpdated,
                                    adressLine1: e.target.value,
                                }),
                            );
                        }, 500)}
                    />
                    <Input
                        className="mb-2 w-50"
                        type="text"
                        label={`${t('address')} 2`}
                        defaultValue={userUpdated?.adressLine2 ?? ''}
                        name="adressLine2"
                        onChange={debounce((e) => {
                            dispatch(
                                selectedShopUserDataUpdatedSet({
                                    ...userUpdated,
                                    adressLine2: e.target.value,
                                }),
                            );
                        }, 500)}
                    />
                </Flex>
                <Flex $gap={22}>
                    <Input
                        className="mb-2 w-50"
                        type="text"
                        label={t('postalcode')}
                        defaultValue={userUpdated?.postalCode ?? ''}
                        name="postalCode"
                        onChange={debounce((e) => {
                            dispatch(
                                selectedShopUserDataUpdatedSet({
                                    ...userUpdated,
                                    postalCode: e.target.value,
                                }),
                            );
                        }, 500)}
                    />
                    <Input
                        className="mb-2 w-50"
                        type="text"
                        label={t('city')}
                        defaultValue={userUpdated?.city ?? ''}
                        name="city"
                        onChange={debounce((e) => {
                            dispatch(
                                selectedShopUserDataUpdatedSet({
                                    ...userUpdated,
                                    city: e.target.value,
                                }),
                            );
                        }, 500)}
                    />
                </Flex>

                <Input
                    className="mb-2"
                    type="text"
                    label={t('country')}
                    defaultValue={userUpdated?.country ?? ''}
                    name="country"
                    onChange={debounce((e) => {
                        dispatch(
                            selectedShopUserDataUpdatedSet({
                                ...userUpdated,
                                country: e.target.value,
                            }),
                        );
                    }, 500)}
                />
            </div>
            {/* <h4 className="mb-2">Settings</h4> */}
            <div className="mb-4">
                <Label className="mb-2">{t('roles')}</Label>
                <Radio
                    className="mb-2"
                    label={t('user')}
                    checked={userUpdated?.role === ShopUserType.User}
                    name="role"
                    onChange={() => {
                        dispatch(
                            selectedShopUserDataUpdatedSet({
                                ...userUpdated,
                                role: ShopUserType.User,
                            }),
                        );
                    }}
                />
                <Radio
                    className="mb-2"
                    label={t('administrator')}
                    checked={userUpdated?.role === ShopUserType.ShopManager}
                    name="role"
                    onChange={() => {
                        dispatch(
                            selectedShopUserDataUpdatedSet({
                                ...userUpdated,
                                role: ShopUserType.ShopManager,
                            }),
                        );
                    }}
                />
            </div>
            <div>
                <Label className="mb-2">{t('permissions')}</Label>
                <Checkbox
                    className="mb-2"
                    defaultChecked={userUpdated?.isAttestent}
                    name="isAttestent"
                    label={t('attestant')}
                    onChange={() => {
                        dispatch(
                            selectedShopUserDataUpdatedSet({
                                ...userUpdated,
                                isAttestent: !userUpdated?.isAttestent,
                            }),
                        );
                    }}
                />
                {/* <Checkbox
                                className="mb-2"
                                defaultChecked={selectedShopUserData?.}
                                label="Attestant"
                            /> */}

                <Checkbox
                    className="mb-2"
                    defaultChecked={userUpdated?.requiresAttestation}
                    name="requiresAttestation"
                    label={t('requiresAttestation')}
                    onChange={() => {
                        dispatch(
                            selectedShopUserDataUpdatedSet({
                                ...userUpdated,
                                requiresAttestation: !userUpdated?.requiresAttestation,
                            }),
                        );
                    }}
                />
            </div>
        </form>
    );
};

const Left = styled.div`
    flex: 2;
    ${({ theme }) => theme.media.xlBelow} {
        flex: 2;
    }
`;

const Right = styled.div`
    flex: 3;
    ${({ theme }) => theme.media.xlBelow} {
        flex: 3;
    }
`;

const UserInfo = styled.div`
    background-color: #ffff;
    padding: 20px;
`;

const LoadingWrapper = styled.div`
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 40px;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.colors.fillGray};
`;
