import Flex from 'components/ui/Flex';
import ThemeButton from 'components/ui/ThemeButton';
import Text from 'components/ui/Text';
import { useTranslation } from 'react-i18next';
import ShopLoadLayout from './ShopLoadLayout';

const ShopMustLogin = ({
    login,
    register,
    hideRegistration,
}: {
    login: () => void;
    register: () => void;
    hideRegistration: boolean;
}) => {
    const { t } = useTranslation();
    return (
        <ShopLoadLayout>
            <Text center>{t('shop_login_required')}</Text>

            <Flex className="text-center">
                <ThemeButton pill={false} className="m-3 mt-4" onClick={login} text={t('signin')} />
                {!hideRegistration && (
                    <ThemeButton pill={false} className="m-3 mt-4" onClick={register} text={t('register')} />
                )}
            </Flex>
        </ShopLoadLayout>
    );
};
export default ShopMustLogin;
