import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import uniqueId from 'lodash.uniqueid';
import { shade } from 'polished';
import React, { forwardRef, useContext, useState } from 'react';
import styled, { ThemeColors, ThemeContext } from 'styled-components/macro';
import Flex from './Flex';

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
    name?: string;
    label?: React.ReactNode;
    subLabel?: string;
    bgColor?: keyof ThemeColors;
    addRightMargin?: boolean;
    iconColor?: string;
};

const Radio = forwardRef((props: InputProps, ref: React.Ref<HTMLInputElement>) => {
    const themeContext = useContext(ThemeContext);
    const {
        name,
        label,
        bgColor,
        addRightMargin = true,
        iconColor,
        className,
        checked,
        defaultChecked,
        disabled,
        ...restProps
    } = props;
    const [id] = useState(restProps.id ?? uniqueId(`${name}-`));

    return (
        <Container align="center" className={`${className} ${addRightMargin ? 'me-3' : ''}`} disabled={disabled}>
            <HiddenRadio
                checked={checked}
                bgColor={bgColor}
                disabled={disabled}
                type="radio"
                id={id}
                name={name}
                ref={ref}
                {...restProps}
            />
            <StyledRadio disabled={disabled} bgColor={bgColor}>
                {(checked || defaultChecked) && (
                    <FontAwesomeIcon fontSize={15} icon={faCheck} color={iconColor || themeContext.colors.textLight} />
                )}
            </StyledRadio>
            {label && (
                <Label disabled={disabled} htmlFor={id} className="mx-3">
                    {label}
                    {restProps.subLabel && <SubLabel>{restProps.subLabel}</SubLabel>}
                </Label>
            )}
        </Container>
    );
});

const Container = styled(Flex)<{ disabled?: boolean }>`
    position: relative;
    > * {
        cursor: pointer;
    }
    ${({ disabled, theme }) =>
        disabled &&
        `
      color: ${theme.colors.textDisabled};
      cursor: unset;
    `}
`;

const HiddenRadio = styled.input<{ bgColor?: keyof ThemeColors; disabled?: boolean }>`
    position: absolute;
    opacity: 0;
    width: 30px;
    height: 30px;
    cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')} !important;

    &:focus-visible:not([disabled]) + div {
        border: 2px solid ${({ theme }) => theme.colors.accent};
    }
    &:hover ~ div {
        background-color: ${({ bgColor, theme, disabled }) => {
            if (disabled) return theme.colors.disabled;
            if (bgColor) return shade(0.03, theme.colors[bgColor]);
            return shade(0.03, theme.colors.fillWhite);
        }};
    }
`;

const Label = styled.label<{ disabled?: boolean }>`
    ${({ disabled }) => disabled && 'cursor: default;'}
`;

const StyledRadio = styled.div<{ bgColor?: keyof ThemeColors; disabled?: boolean }>`
    display: flex;
    width: 30px;
    height: 30px;
    background-color: ${({ theme, bgColor }) => (bgColor ? theme.colors[bgColor] : theme.colors.fillWhite)};
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    transition: all 150ms;
    border: 1px solid ${({ theme }) => theme.colors.borderLight};
    border-radius: 15px;
    flex-shrink: 0;

    ${({ disabled, theme }) =>
        disabled &&
        `
    background-color: ${theme.colors.disabled};
    cursor: unset;
  `}
`;

const SubLabel = styled.p`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.textFaded};
    margin-bottom: 0;
`;

export default Radio;
