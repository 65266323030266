"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SortShopUserToJSON = exports.SortShopUserFromJSONTyped = exports.SortShopUserFromJSON = exports.SortShopUser = void 0;
/**
 *
 * @export
 */
exports.SortShopUser = {
    None: 0,
    FirstName: 1,
    LastName: 2,
    Email: 3,
    PhoneNumber: 4,
    Orders: 5,
    Created: 6
};
function SortShopUserFromJSON(json) {
    return SortShopUserFromJSONTyped(json, false);
}
exports.SortShopUserFromJSON = SortShopUserFromJSON;
function SortShopUserFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.SortShopUserFromJSONTyped = SortShopUserFromJSONTyped;
function SortShopUserToJSON(value) {
    return value;
}
exports.SortShopUserToJSON = SortShopUserToJSON;
