import { faFile, faFileExcel, faFilePdf, faFilePowerpoint, faFileWord } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Text from 'components/ui/Text';
import { useState } from 'react';
import { Direction } from 'reactstrap/es/Dropdown';
import styled from 'styled-components';
import Flex from './Flex';
import HeaderIconButton from './HeaderIconButton';
import useOnClickOutside from 'hooks/useCloseOnClickOutside';
const Files = ({ files, direction, className }: { files: any[]; direction?: Direction; className?: string }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const ref = useOnClickOutside(() => toggle(), dropdownOpen);

    const getIcon = (ext: string) => {
        switch (ext) {
            case '.pdf':
                return faFilePdf;
            case '.xlsx':
            case '.xls':
            case '.csv':
                return faFileExcel;
            case '.doc':
            case '.docx':
                return faFileWord;
            case '.ppt':
            case '.pptx':
                return faFilePowerpoint;
            default:
                return faFile;
        }
    };

    if (!files?.length) {
        return null;
    }

    return (
        <div style={{ position: 'relative' }} ref={ref}>
            <HeaderIconButton small icon={faFile} onClick={() => toggle()} />
            {dropdownOpen && (
                <StyledFilesCard>
                    {files?.map((file, index) => (
                        <a
                            key={index}
                            href={file.url}
                            title={file?.description}
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => toggle()}
                        >
                            <StyledDropdownItem>
                                <Flex align="baseline" $gap={8}>
                                    <FontAwesomeIcon icon={getIcon(file.extension)} />
                                    <StyledText>{file.name}</StyledText>
                                </Flex>
                            </StyledDropdownItem>
                        </a>
                    ))}
                </StyledFilesCard>
            )}
        </div>
    );
};

export default Files;

const StyledText = styled(Text)`
    white-space: normal;
    word-wrap: break-word;
`;

const StyledFilesCard = styled.div`
    min-width: 200px;
    background: #ffffff;
    box-shadow: 0px 0px 4px 0px #00000040;
    top: 100%;
    right: -40px;
    box-shadow: 0px 0px 24px 0 rgba(0, 0, 0, 0.16);
    position: absolute;
    font-size: 12px;
`;

const StyledDropdownItem = styled.div`
    padding: 12px;
    border-bottom: 1px solid #f5f5f5;
    cursor: pointer;
    &:hover {
        background-color: #f5f5f5;
    }
`;
