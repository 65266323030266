"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomDeliveryAddressSettingsToJSON = exports.CustomDeliveryAddressSettingsFromJSONTyped = exports.CustomDeliveryAddressSettingsFromJSON = exports.CustomDeliveryAddressSettings = void 0;
/**
 *
 * @export
 */
exports.CustomDeliveryAddressSettings = {
    Inactive: 0,
    ActiveAndOptional: 1,
    ActiveAndRequired: 2
};
function CustomDeliveryAddressSettingsFromJSON(json) {
    return CustomDeliveryAddressSettingsFromJSONTyped(json, false);
}
exports.CustomDeliveryAddressSettingsFromJSON = CustomDeliveryAddressSettingsFromJSON;
function CustomDeliveryAddressSettingsFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.CustomDeliveryAddressSettingsFromJSONTyped = CustomDeliveryAddressSettingsFromJSONTyped;
function CustomDeliveryAddressSettingsToJSON(value) {
    return value;
}
exports.CustomDeliveryAddressSettingsToJSON = CustomDeliveryAddressSettingsToJSON;
