import Text from '../ui/Text';
import styled from 'styled-components';
import { StandardCheckout } from 'microshop-api';
import Input from 'components/ui/Input';
import Flex from 'components/ui/Flex';
import { ChangeEvent } from 'react';
import useAppSelector from 'hooks/useAppSelector';
import { checkoutSet, selectCheckout } from 'store/reducers/orderSlice';
import useAppDispatch from 'hooks/useAppDispatch';
import { useTranslation } from 'react-i18next';

export const Delivery = ({ editEnabled }: { editEnabled: boolean }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const checkout = useAppSelector(selectCheckout);

    if (!checkout) return null;

    const {
        address1,
        postalCode,
        city,
        country,
        firstName,
        lastName,
        name,
        email,
        phone,
        comment,
        emailLocked,
    } = checkout;

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        dispatch(checkoutSet({ name: name as keyof StandardCheckout, value }));
    };

    return (
        <>
            <DeliveryInfo className="mb-3">
                {!editEnabled && (
                    <>
                        <h3>{t('deliveryAddress', 'Deliveryaddress')}</h3>
                        <Flex $wrap style={{ rowGap: '4px', columnGap: '18px' }}>
                            <AddressDetail>
                                <Text bold>{t('name')}</Text>
                                <Text>{name}</Text>
                            </AddressDetail>
                            <div></div>
                            <AddressDetail>
                                <Text bold>{t('email')}</Text>
                                <Text>{email}</Text>
                            </AddressDetail>
                            <AddressDetail>
                                <Text bold>{t('phone')}</Text>
                                <Text>{phone}</Text>
                            </AddressDetail>
                            <AddressDetail>
                                <Text bold>{t('address')}</Text>
                                <Text>{address1}</Text>
                            </AddressDetail>
                            <AddressDetail>
                                <Text bold>{t('country')}</Text>
                                <Text>{country}</Text>
                            </AddressDetail>
                            <AddressDetail>
                                <Text bold>{t('postalcode')}</Text>
                                <Text>{postalCode}</Text>
                            </AddressDetail>
                            <AddressDetail>
                                <Text bold>{t('city')}</Text>
                                <Text>{city}</Text>
                            </AddressDetail>

                            {comment && (
                                <AddressDetail>
                                    <Text bold>{t('comment')}</Text>
                                    <Text>{comment}</Text>
                                </AddressDetail>
                            )}
                        </Flex>
                    </>
                )}
                {editEnabled && (
                    <>
                        <h3>{t('deliveryAddress', 'Deliveryaddress')}</h3>
                        <Flex $gap={16}>
                            <StyledInput
                                type="text"
                                name="name"
                                label={t('name')}
                                className="mb-2"
                                defaultValue={name ?? ''}
                                onChange={(e) => handleChange(e)}
                            />
                        </Flex>
                        <Flex $gap={16}>
                            <StyledInput
                                name="email"
                                label={t('email')}
                                disabled={emailLocked}
                                bgColor={emailLocked ? 'borderLight' : 'fillWhite'}
                                className="mb-2"
                                type="email"
                                defaultValue={email ?? ''}
                                onChange={(e) => handleChange(e)}
                            />
                            <StyledInput
                                label={t('phone')}
                                name="phone"
                                className="mb-2"
                                type="phone"
                                defaultValue={phone ?? ''}
                                onChange={(e) => handleChange(e)}
                            />
                        </Flex>
                        <Flex $gap={16}>
                            <StyledInput
                                label={t('address')}
                                name="address1"
                                className="mb-2"
                                type="text"
                                defaultValue={address1 ?? ''}
                                onChange={(e) => handleChange(e)}
                            />
                            <StyledInput
                                label={t('country')}
                                name="country"
                                type="text"
                                className="mb-2"
                                defaultValue={country ?? ''}
                                onChange={(e) => handleChange(e)}
                            />
                        </Flex>
                        <Flex $gap={16}>
                            <StyledInput
                                label={t('postalcode')}
                                name="postalCode"
                                className="mb-2"
                                type="text"
                                defaultValue={postalCode ?? ''}
                                onChange={(e) => handleChange(e)}
                            />
                            <StyledInput
                                label={t('city')}
                                name="city"
                                className="mb-2"
                                type="text"
                                defaultValue={city ?? ''}
                                onChange={(e) => handleChange(e)}
                            />
                        </Flex>
                        <Input
                            label={t('comment')}
                            name="comment"
                            className="mb-2"
                            type="text"
                            defaultValue={comment ?? ''}
                            onChange={(e) => handleChange(e)}
                        />
                    </>
                )}
            </DeliveryInfo>
        </>
    );
};
const DeliveryInfo = styled.div`
    background-color: #ffff;
    padding: 20px;
`;

const StyledInput = styled(Input)`
    width: 100%;
    flex: 1;
`;

const AddressDetail = styled.div`
    flex: 1 1 calc(50% - 16px);
`;
