"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderCartCheckoutWithAttestationToJSON = exports.OrderCartCheckoutWithAttestationFromJSONTyped = exports.OrderCartCheckoutWithAttestationFromJSON = exports.instanceOfOrderCartCheckoutWithAttestation = void 0;
var runtime_1 = require("../runtime");
var CartSummary_1 = require("./CartSummary");
var ChangeAttestation_1 = require("./ChangeAttestation");
var Checkout_1 = require("./Checkout");
/**
 * Check if a given object implements the OrderCartCheckoutWithAttestation interface.
 */
function instanceOfOrderCartCheckoutWithAttestation(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfOrderCartCheckoutWithAttestation = instanceOfOrderCartCheckoutWithAttestation;
function OrderCartCheckoutWithAttestationFromJSON(json) {
    return OrderCartCheckoutWithAttestationFromJSONTyped(json, false);
}
exports.OrderCartCheckoutWithAttestationFromJSON = OrderCartCheckoutWithAttestationFromJSON;
function OrderCartCheckoutWithAttestationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'orderId': !(0, runtime_1.exists)(json, 'orderId') ? undefined : json['orderId'],
        'isEditable': !(0, runtime_1.exists)(json, 'isEditable') ? undefined : json['isEditable'],
        'cart': !(0, runtime_1.exists)(json, 'cart') ? undefined : (0, CartSummary_1.CartSummaryFromJSON)(json['cart']),
        'checkout': !(0, runtime_1.exists)(json, 'checkout') ? undefined : (0, Checkout_1.CheckoutFromJSON)(json['checkout']),
        'attestation': !(0, runtime_1.exists)(json, 'attestation') ? undefined : (0, ChangeAttestation_1.ChangeAttestationFromJSON)(json['attestation']),
    };
}
exports.OrderCartCheckoutWithAttestationFromJSONTyped = OrderCartCheckoutWithAttestationFromJSONTyped;
function OrderCartCheckoutWithAttestationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'orderId': value.orderId,
        'isEditable': value.isEditable,
        'cart': (0, CartSummary_1.CartSummaryToJSON)(value.cart),
        'checkout': (0, Checkout_1.CheckoutToJSON)(value.checkout),
        'attestation': (0, ChangeAttestation_1.ChangeAttestationToJSON)(value.attestation),
    };
}
exports.OrderCartCheckoutWithAttestationToJSON = OrderCartCheckoutWithAttestationToJSON;
