import { assortment } from './assortment';
import { cart } from './cart';
import { oid } from './oid';
import { shop } from './shop';
import { customer } from './customer';
import { user } from './user';
import { attestation } from './attestation';
import { order } from './order';
import { shopUser } from './shopUser';

const services = {
    attestation,
    assortment,
    shop,
    oid,
    cart,
    customer,
    user,
    shopUser,
    order,
};

export default services;
