import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { t } from 'i18next';
import {
    CartInput,
    CustomTag,
    ListProduct,
    ProductCollection,
    ProductImage,
    ProductStock,
    ShopCompleteProduct,
    ShopCompleteVariation,
    ShopProductImage,
    StockLevel,
    StockType,
    TagPosition,
    TagType,
} from 'microshop-api';
import services from 'services';
import { AppDispatch, RootState } from 'store';

const productHistory: Record<string, ShopCompleteProduct> = {};

export type TagSettings = {
    productNew: 'New';
    productOutlet: 'Outgoing';
    productUpComming: 'Upcoming';
    variationNew: 'New color';
    variationOutlet: 'Outgoing color';
    variationUpComing: 'Upcoming color';
};

export enum OrderLayout {
    Normal,
    Matrix,
    MatrixCompact,
}

export type StockOptions = {
    stockInfo: StockSettings[];
    outOfStockMessage?: { label?: string; description?: string };
};

export function getSkuStock(
    settings: StockSettings[],
    stockRows: ProductStock[],
    variation?: ShopCompleteVariation,
): SkuStock {
    let stockNumber = 0;
    let stockLevel: StockLevel = 0;
    let innerStockNumber = 0;
    const unknown = settings.every((ss) => ss.visible === false);

    const isProductVariationOutlet = variation?.tags?.some((t: any) => t === TagType.Outlet);

    const globalSetting = settings.find((ss) => ss.type === 'Global');
    const globalStock = stockRows.find((st) => st.type === StockType.Global);
    const globalHideForOutlet = globalSetting?.hideForOutlet && isProductVariationOutlet;
    const globalVisible = !!globalSetting?.visible && !globalHideForOutlet;
    const globalInMatrix = globalVisible && globalSetting.excludeFromStockAtHand !== true;

    if (globalInMatrix && globalStock?.stockLevel) {
        stockLevel = globalStock.stockLevel;
    }

    if (globalVisible && globalStock?.value) {
        if (globalInMatrix) stockNumber += globalStock.value;
        innerStockNumber += globalStock.value;
    }

    function increaseStockNumberWithStock(stock: ProductStock, setting: StockSettings, visible?: boolean) {
        if (stock?.stockLevel && stock.stockLevel > stockLevel && !setting.excludeFromStockAtHand && visible === true) {
            stockLevel = stock.stockLevel;
        }

        if (stock?.value) {
            // Increase stockNumber with the value. If locally or regional hidden, readd it to the global stock.
            if ((visible && !setting.excludeFromStockAtHand) || globalInMatrix) {
                stockNumber += stock.value;
            }

            if (visible || globalVisible) {
                innerStockNumber += stock.value;
            }
        }
    }

    const localStock = stockRows.find((st) => st.type === StockType.Local);
    const localSetting = settings.find((ss) => ss.type === 'Local');
    const localVisible = localSetting?.visible && !(isProductVariationOutlet && localSetting?.hideForOutlet);

    if (localStock && localSetting) increaseStockNumberWithStock(localStock, localSetting, localVisible);

    const regionStock = stockRows.find((st) => st.type === StockType.Region);
    const regionSetting = settings.find((ss) => ss.type === 'Region');
    const regionVisible = regionSetting?.visible && !(isProductVariationOutlet && regionSetting?.hideForOutlet);

    if (regionStock && regionSetting) increaseStockNumberWithStock(regionStock, regionSetting, regionVisible);

    return {
        stockNumber,
        innerStockNumber,
        unknown,
        stockLevel,
    };
}

export type ProductCollectionVariation = ProductCollection & {
    variationNumber: string | null | undefined;
};

type SkuStock = {
    stockNumber: number;
    innerStockNumber: number;
    unknown: boolean;
    stockLevel: StockLevel;
};

type Product = {
    productNumber?: string;
    variation?: ProductCollectionVariation;
    inputs?: CartInput[];
    loading: boolean;
    categrory?: string;
};

type ProductState = {
    product: Product;
    quickViewProduct: Product;

    quickView: boolean;
    orderLayout: OrderLayout;
    tagSettings?: TagSettings;
};

const initialState: ProductState = {
    product: {
        loading: false,
        inputs: [],
    },
    quickView: false,
    quickViewProduct: {
        loading: false,
    },

    orderLayout: OrderLayout.Normal,
};

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        quickViewToggled(state, action: PayloadAction<boolean>) {
            state.quickView = action.payload;
        },
        variationSelected(state, action: PayloadAction<ProductCollectionVariation>) {
            state.product.variation = action.payload;
            state.product.inputs = action.payload.inputs
                ? action.payload.inputs.map((input) => ({
                      key: input.key!,
                      input: '',
                  }))
                : [];
        },

        inputsSelected(state, action: PayloadAction<Product['inputs']>) {
            state.product.inputs = action.payload;
        },
        quickVariationSelected(state, action: PayloadAction<ProductCollectionVariation>) {
            state.quickViewProduct.variation = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getProduct.pending, (state, action) => {
            state.product.loading = true;
            state.product.productNumber = undefined;
            state.product.categrory = undefined;
        });
        builder.addCase(getProduct.fulfilled, (state, action) => {
            const { productNumber, category } = action.payload;
            state.product.loading = false;
            state.product.productNumber = productNumber;
            state.product.categrory = category;
        });
        builder.addCase(getProduct.rejected, (state, action) => {
            state.product.loading = false;
        });

        builder.addCase(toggleQuickView.pending, (state) => {
            state.quickView = true;
            state.quickViewProduct.loading = true;
            state.quickViewProduct.productNumber = undefined;
            state.quickViewProduct.categrory = undefined;
        });
        builder.addCase(toggleQuickView.fulfilled, (state, action) => {
            const { productNumber, category } = action.payload;
            state.quickViewProduct.loading = false;
            state.quickViewProduct.productNumber = productNumber;
            state.quickViewProduct.categrory = category ?? undefined;
        });
        builder.addCase(setOrderLayout.fulfilled, (state, action) => {
            state.orderLayout = action.payload;
        });
    },
});

// ## Thunks
export const getProduct = createAsyncThunk<
    { productNumber: string; inputValues: CartInput[]; category: string },
    { category: string; pnrOrSlug: string; featuredColor?: string; forceReload?: boolean },
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>('product/getProduct', async (par, { dispatch, rejectWithValue, getState }) => {
    const getProductAction = await dispatch(getProductNoState(par));

    if (getProductNoState.fulfilled.match(getProductAction)) {
        const product = getProductAction.payload;
        const variation = getCompleteVariation(product, par.featuredColor);
        const variationCollection = variation?.collections?.[0];
        dispatch(
            variationSelected({
                variationNumber: variation?.variationNumber,
                split: variationCollection?.split,
                collectionId: variationCollection?.collectionId,
                inputs: variationCollection?.inputs,
                price: variationCollection?.price,
            }),
        );

        const inputValues = variationCollection?.inputs
            ? variationCollection.inputs.reduce<Array<{ key: string; input: string }>>((acc, input) => {
                  acc.push({ key: input.key!, input: '' });
                  return acc;
              }, [])
            : [];

        if (product.productNumber)
            return { productNumber: product.productNumber, inputValues: inputValues, category: product.nodeId ?? '' };
    }

    return rejectWithValue('No product found');
});

export const toggleQuickView = createAsyncThunk<
    { productNumber: string; category: string },
    { category: string; pnrOrSlug: string; featuredColor?: string },
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>('product/toggleQuickView', async (par, { dispatch, rejectWithValue }) => {
    const getProductAction = await dispatch(getProductNoState(par));

    if (getProductNoState.fulfilled.match(getProductAction)) {
        const product = getProductAction.payload;
        const variation = getCompleteVariation(product, par.featuredColor);
        const variationCollection = variation?.collections?.[0];

        dispatch(
            variationSelected({
                variationNumber: variation?.variationNumber,
                split: variationCollection?.split,
                collectionId: variationCollection?.collectionId,
                price: variationCollection?.price,
            }),
        );
        if (product.productNumber) return { productNumber: product.productNumber, category: product.nodeId ?? '' };
    }

    return rejectWithValue('No product found');
});

export const getProductNoState = createAsyncThunk<
    ShopCompleteProduct,
    { category: string; pnrOrSlug: string; forceReload?: boolean },
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>('product/getProductNoState', async (par, { getState, dispatch, rejectWithValue }) => {
    const { category, pnrOrSlug, forceReload } = par;

    let product: ShopCompleteProduct = productHistory[pnrOrSlug + category];
    if (!product || forceReload) {
        try {
            const response = await services.assortment.getProduct({ category: category, product: pnrOrSlug });
            product = response;
        } catch (e) {
            return rejectWithValue('No product found');
        }
    }
    if (product.productNumber) productHistory[product.productNumber + category] = product;
    if (product.slug) productHistory[product.slug + category] = product;
    return product;
});

const getTagTranslation = (tagType: TagType, tagSettings?: TagSettings, isVariation?: boolean) => {
    if (isVariation) {
        switch (tagType) {
            case TagType.New:
                return tagSettings?.variationNew ?? t('productTag.newVariation', 'New Color');
            case TagType.Outlet:
                return tagSettings?.variationOutlet ?? t('productTag.outletVariation', 'Color on sale');
            case TagType.UpComming:
                return tagSettings?.variationUpComing ?? t('productTag.upcomingVariation', 'Upcoming Color');
            default:
                return '';
        }
    } else {
        switch (tagType) {
            case TagType.New:
                return tagSettings?.productNew ?? t('productTag.new', 'New');
            case TagType.Outlet:
                return tagSettings?.productOutlet ?? t('productTag.outlet', 'Outlet');
            case TagType.Sustainable:
                return t('productTag.sustainable', 'Sustainable');
            case TagType.UpComming:
                return tagSettings?.productUpComming ?? t('productTag.upcoming', 'Upcoming');
        }
    }
};

export const setOrderLayout = createAsyncThunk<
    OrderLayout,
    OrderLayout,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>('product/setOrderlayout', async (type) => {
    persistOrderLayout(type);
    return type;
});

// ## Selectors
const selectProductNumber = (state: RootState) => state.product.product.productNumber;
const selectProductCategory = (state: RootState) => state.product.product.categrory;
export const selectProductLoading = (state: RootState) => state.product.product.loading;
const selectSelectedVariation = (state: RootState) => state.product.product.variation;
const selectListProduct = (_: RootState, product?: ListProduct) => product;
const selectTagSettings = (state: RootState) => state.product.tagSettings;
const selectLang = (state: RootState) => state.shop.info?.language;
const selectQuickView = (state: RootState) => state.product.quickView;
const selectQuickViewProductNumber = (state: RootState) => state.product.quickViewProduct.productNumber;
const selectQuickViewProductLoading = (state: RootState) => state.product.quickViewProduct.loading;
const selectQuickViewProductCategory = (state: RootState) => state.product.quickViewProduct.categrory;
const selectSelectedQuickViewVariation = (state: RootState) => state.product.quickViewProduct.variation;
export const selectProductInputs = (state: RootState) => state.product.product.inputs;

const selectQuickViewProduct = createSelector(
    selectQuickViewProductNumber,
    selectQuickViewProductLoading,
    selectQuickViewProductCategory,
    (pnr, loading, category): ShopCompleteProduct | undefined => {
        if (loading) return selectProduct.lastResult();
        return pnr ? productHistory[pnr + category] : undefined;
    },
);

const selectQuickViewVariations = createSelector(selectQuickViewProduct, getCollectionVariations);
const selectQuickViewVariation = createSelector(
    selectQuickViewVariations,
    selectSelectedQuickViewVariation,
    getSelectedVariation,
);

export const selectQuickViewDetails = createSelector(
    selectQuickView,
    selectQuickViewProduct,
    selectQuickViewVariation,
    selectQuickViewVariations,
    selectQuickViewProductLoading,
    (showQuickView, quickViewProduct, quickViewVariation, quickViewVariations, quickViewLoading) => ({
        showQuickView,
        quickViewProduct,
        quickViewVariation,
        quickViewVariations,
        quickViewLoading,
    }),
);

const selectProduct = createSelector(
    selectProductNumber,
    selectProductLoading,
    selectProductCategory,
    (pnr, loading, category): ShopCompleteProduct | undefined => {
        if (loading) return selectProduct.lastResult();

        return pnr ? productHistory[pnr + category] : undefined;
    },
);

const selectVariations = createSelector(selectProduct, getCollectionVariations);
const selectVariation = createSelector(selectVariations, selectSelectedVariation, getSelectedVariation);

export const selectProductDetails = createSelector(
    selectProduct,
    selectVariation,
    selectVariations,
    selectProductLoading,
    (product, variation, variations, loading) => ({
        product,
        variation,
        variations,
        loading,
    }),
);

export const selectProductListTags = createSelector(
    selectListProduct,
    selectTagSettings,
    (product, tagSettings): CustomTag[] => {
        const productTags = product?.tags?.map((tag) => getCustomBadgeTag(tag, tagSettings)) ?? [];

        const variationTags: CustomTag[] =
            product?.variations?.reduce((acc: CustomTag[], v) => {
                if (v.tags?.length) {
                    // Remove duplicates if multiple colors have same tag. If product has for example "TagType.New" it will override and we will not display variation tags new.
                    const filtered = v.tags.filter(
                        (tag) => !productTags.some((pt) => pt.type === tag) && !acc.some((vt) => vt.type === tag),
                    );
                    return [...acc, ...filtered.map((tag) => getCustomBadgeTag(tag, tagSettings, true))];
                }

                return acc;
            }, new Array<CustomTag>()) ?? [];

        return [...(product?.customTags ?? []), ...productTags, ...variationTags];
    },
);

export const selectStockSettings = (state: RootState) => {
    //   let option = options?.find((o) => o.brand === product?.productBrandName);
    //   if (!option) option = options?.find((o) => !o.brand);
    //   const stockInfo = option?.stockInfo?.filter((si) => si.type !== 'General') ?? getDefaultStockOption();
    const stockInfo = getDefaultStockOption();
    return { stockInfo, outOfStockMessage: { label: '', description: '' } };
};

type ProductTags = {
    priceTags: CustomTag[];
    badgeTags: CustomTag[];
};
export const selectProductDetailsTags = createSelector(
    selectProduct,
    selectTagSettings,
    (product, tagSettings): ProductTags => {
        const priceTags = product?.customTags?.filter((t) => t.position === TagPosition.Price) ?? [];
        const customBadgeTags = product?.customTags?.filter((t) => t.position === TagPosition.Badge) ?? [];
        const productTags = product?.tags?.map((tag) => getCustomBadgeTag(tag, tagSettings)) ?? [];

        return {
            priceTags,
            badgeTags: [...customBadgeTags, ...productTags],
        };
    },
);

export const selectActiveImages = createSelector(
    selectProduct,
    selectVariation,
    (product, variation): ProductImage[] => {
        const productPictures =
            (!product?.hasVariations || !variation || !variation.images?.length
                ? product?.pictures
                : product?.pictures?.filter((image) => image.type === 'Imagepicture')) ?? [];

        const split = variation?.split;
        const collectionId = variation?.collectionId;

        let newVariationImages: ShopProductImage[] = [];
        let variationImages: ShopProductImage[] = [];

        // Add images with matching split and collectionId to newVariationImages
        newVariationImages =
            variation?.images?.filter((img) => collectionId === img.collectionId && img.split === split) || [];

        // Use a Set to track unique angles in variationImages
        const uniqueAngles = new Set();

        // Iterate through variationImages and add unique angles
        variation?.images?.forEach((img) => {
            if (img.angle !== 'none') {
                if (!uniqueAngles.has(img.angle)) {
                    uniqueAngles.add(img.angle);
                    variationImages.push(img);
                }
            } else {
                variationImages.push(img);
            }
        });

        // Add images from variationImages to newVariationImages where angle doesn't exist
        variationImages.forEach((old) => {
            const angleExists = newVariationImages.some((newImg) => newImg.angle === old.angle);
            if (old.angle !== 'none') {
                if (!angleExists) {
                    newVariationImages.push(old);
                }
            } else {
                newVariationImages.push(old);
            }
        });

        // Sort newVariationImages based on the order of images in variation?.images
        newVariationImages.sort((a, b) => {
            const indexA = variation?.images?.indexOf(a);
            const indexB = variation?.images?.indexOf(b);
            return (indexA || 0) - (indexB || 0);
        });

        const all = [...newVariationImages, ...productPictures];

        return all.filter((image, i, array) => array.findIndex((img) => img.fileName === image.fileName) === i); // Remove duplicates
    },
);

export const selectProductAccordionData = createSelector(selectProduct, (product) => {
    const attributes = product?.attributes;
    const certifications = product?.marks?.certifications;
    const careInstructions = product?.marks?.careInstructions;
    const certificationDescription = product?.attributes?.certificationDescription?.[0];
    const documents = product?.documents;

    return {
        attributes,
        certifications,
        certificationDescription,
        careInstructions,
        documents,
    };
});

export type CollectionVariation = ShopCompleteVariation & ProductCollectionVariation;

export const selectNumFormat = createSelector(selectLang, (lang) => new Intl.NumberFormat(lang || 'en'));

export type StockInfoType = 'Global' | 'Region' | 'Local' | 'General';

// ## Utils
export type StockSettings = {
    type: StockInfoType;
    visible: boolean;
    excludeFromStockAtHand: boolean;
    label?: string;
    description?: string;
    hideForOutlet: boolean;
};
export function getDefaultStockOption(): StockSettings[] {
    return [
        {
            type: 'Local',
            visible: true,
            excludeFromStockAtHand: false,
            hideForOutlet: false,
        },
        {
            type: 'Region',
            visible: true,
            excludeFromStockAtHand: false,
            hideForOutlet: false,
        },
        {
            type: 'Global',
            visible: true,
            excludeFromStockAtHand: true,
            hideForOutlet: false,
        },
    ];
}
export function getStockType(type: StockInfoType): StockType {
    switch (type) {
        case 'Local':
            return StockType.Local;
        case 'Region':
            return StockType.Region;
        case 'Global':
            return StockType.Global;
        case 'General':
            return StockType.Custom; // ! General should be removed
    }
}

function getCustomBadgeTag(tagType: TagType, tagSettings?: TagSettings, isVariation?: boolean) {
    return {
        type: tagType,
        value: getTagTranslation(tagType, tagSettings, isVariation),
        position: TagPosition.Badge,
    };
}

function persistOrderLayout(type: OrderLayout) {
    localStorage.setItem('order_layout', type.toString());
}

function getCompleteVariation(product: ShopCompleteProduct, featuredColor?: string): ShopCompleteVariation | undefined {
    if (product.variations?.length) {
        const featured = product.variations.find((v) => (featuredColor ? v.colorCode === featuredColor : v.featured));
        return featured || product.variations[0];
    }
}

function getCollectionVariation(
    product?: ShopCompleteProduct,
    splitVariation?: ProductCollectionVariation,
): CollectionVariation | undefined {
    if (!splitVariation) return;

    const variation = product?.variations?.find((v) => v.variationNumber === splitVariation.variationNumber);
    if (!variation) return;
    const collection = variation.collections?.find(
        (c) => c.collectionId === splitVariation.collectionId && c.split === splitVariation.split,
    );
    const collectionVariation = { ...splitVariation, ...variation };

    const collectionPrice = collection?.price ? collection.price : variation.price;
    const collectionImage = collection
        ? variation.images?.find(
              (img) => img.collectionId === collection?.collectionId && img.split === collection.split,
          )
        : undefined;

    if (collectionPrice) collectionVariation.price = collectionPrice;
    if (collectionImage) collectionVariation.image = collectionImage;

    return collectionVariation;
}

function getCollectionVariations(product?: ShopCompleteProduct): CollectionVariation[] {
    if (!product?.variations?.length) return [];
    const variations = product.hasVariations ? product.variations : [product.variations[0]];

    const collectionVariations = variations.flatMap((v): CollectionVariation[] => {
        if (!v.variationNumber) return [];

        const collections = v.collections;
        if (!collections?.length) return [{ ...v, variationNumber: v.variationNumber }];

        return collections
            .map((c) => getCollectionVariation(product, { ...c, variationNumber: v.variationNumber }))
            .filter((cv): cv is CollectionVariation => !!cv);
    });

    return collectionVariations;
}

function getSelectedVariation(
    variations: CollectionVariation[],
    selectedVariation?: ProductCollectionVariation,
): CollectionVariation | undefined {
    const variation = variations.find(
        (v) =>
            v.variationNumber === selectedVariation?.variationNumber &&
            v.collectionId === selectedVariation?.collectionId &&
            v.split === selectedVariation?.split,
    );

    return variation;
}

// ## Exports
export const { quickViewToggled, quickVariationSelected, variationSelected, inputsSelected } = productSlice.actions;
export default productSlice.reducer;
