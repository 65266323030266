import Flex from 'components/ui/Flex';
import Pagination from 'components/ui/Pagination';
import Text from 'components/ui/Text';

import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useOnClickOutside from 'hooks/useCloseOnClickOutside';

import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { Col, Row } from 'reactstrap';
import { AssortmentPageInfo, loadProducts } from 'store/reducers/assortmentSlice';
import { quickVariationSelected, quickViewToggled, selectQuickViewDetails } from 'store/reducers/productSlice';
import styled from 'styled-components/macro';
import ProductBuy from './ProductBuy';
import ProductCard from './ProductCard';

type ProductCardListProps = {
    products: any[];
    pending: boolean;
    morePending?: boolean;
    assortmentPageInfo?: AssortmentPageInfo;
    category?: any;
    categoryPath?: any;
    gtm?: boolean;
    center?: boolean;
};
const ProductCardList: React.FC<ProductCardListProps> = ({
    products,
    pending,
    morePending,
    assortmentPageInfo,
    category,
    categoryPath,
    gtm,
    center,
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const {
        quickViewProduct,
        quickViewVariation,
        quickViewVariations,
        showQuickView,
        quickViewLoading,
    } = useAppSelector(selectQuickViewDetails);
    const [quickBuyLocation, setQuickBuyLocation] = useState(0);
    const quickViewRef = useOnClickOutside(() => dispatch(quickViewToggled(false)), showQuickView);

    useEffect(() => {
        if (!quickViewProduct?.hasVariations) {
            dispatch(quickVariationSelected(quickViewVariations?.[0]));
        }
    }, [quickViewProduct, quickViewVariations, dispatch]);

    if (pending)
        return (
            <ListWrapper>
                <Skeleton width="100%" height={420} />
            </ListWrapper>
        );

    const handleSelect = (ref: React.MutableRefObject<HTMLElement | null>) => {
        const card = ref.current?.parentElement;
        const height = card?.clientHeight || 0;
        const offset = card?.offsetTop || 0;

        setQuickBuyLocation(offset + height - 55);
    };
    return products?.length ? (
        <>
            <ListWrapper
                className="position-relative mb-4"
                itemScope
                itemType="https://schema.org/ItemList"
                itemProp="itemListElement"
            >
                <Row className={`m-0 ${center ? 'justify-content-md-center' : ''}`}>
                    {products?.map((product: any, i) => (
                        <Fragment key={`${product.productNumber}-${i}`}>
                            <Col className="d-flex p-0" xs="6" sm="4" lg="3">
                                <ProductCard
                                    product={product}
                                    onBuy={handleSelect}
                                    category={category}
                                    gtm={gtm}
                                    categoryPath={categoryPath}
                                />
                                <meta itemProp="position" content={`${i}`} />
                            </Col>
                        </Fragment>
                    ))}
                </Row>
                {showQuickView && (
                    <Buy
                        ref={quickViewRef}
                        column
                        className="p-4 my-1 position-absolute"
                        style={{ top: quickBuyLocation }}
                    >
                        <Flex className="ms-3 px-2 pt-2" align="end" justify="between">
                            {(quickViewProduct && <Text fontSize={18}>{quickViewProduct?.productName}</Text>) ||
                                (quickViewLoading && (
                                    <div className="me-4 flex-grow-1">
                                        <Skeleton height={34} width="100%"></Skeleton>
                                    </div>
                                ))}
                        </Flex>
                        <ProductBuy
                            product={quickViewProduct}
                            variation={quickViewVariation}
                            variations={quickViewVariations}
                            pending={quickViewLoading}
                            onVariationSelected={(v) => dispatch(quickVariationSelected(v))}
                            categoryName={category?.name || undefined}
                        />
                    </Buy>
                )}
            </ListWrapper>
            {morePending && (
                <ListWrapper>
                    <Skeleton count={1} height={420} />
                </ListWrapper>
            )}
            <Pagination
                page={assortmentPageInfo?.currentPage!}
                pages={assortmentPageInfo?.totalPages!}
                pageSize={assortmentPageInfo?.pageSize!}
                itemCount={products.length}
                itemsTotal={assortmentPageInfo?.totalItems!}
                label={''}
                setPage={(page: number) => dispatch(loadProducts({ page }))}
                compact={false}
            />
        </>
    ) : null;
};

export default ProductCardList;

const Buy = styled(Flex)`
    background: ${({ theme }) => theme.colors.fill};
    z-index: 4;
    left: -0.8rem;
    right: -0.8rem;
    ${({ theme }) => theme.shadows.high};
`;

const ListWrapper = styled.div`
    flex-grow: 1;
`;
