import Flex from 'components/ui/Flex';
import useAppSelector from 'hooks/useAppSelector';
import { useTranslation } from 'react-i18next';
// import { B2CCartTotal } from 'nwg-commerce-api';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components/macro';
import { DisplayPrice } from './PriceValue';
import { use } from 'i18next';

type TotalProps = {
    className?: string;
};
export const Total = ({ className }: TotalProps) => {
    const { t } = useTranslation();
    const { shop, cart } = useAppSelector(({ shop, cart }) => ({ shop: shop.shop, cart: cart.cart }));
    const usePoints = cart.currency === 'points';
    return (
        <Wrapper className={className}>
            <CostRows total={cart.total} usePoints={usePoints}>
                <CostRow label={t('fee_shipping', 'Shipping Cost')} value={cart.shippingFee} usePoints={usePoints} />
                <CostRow label={t('discount', 'Discount')} value={cart.discount} usePoints={usePoints} />
                <CostRow label={t('fee_admin', 'Admin. fee')} value={cart.fees} usePoints={usePoints} />
                <CostRow label={t('vat', 'VAT')} value={cart.vat} usePoints={usePoints} />
                <CostRow
                    label={
                        cart?.totalInclVat
                            ? t('total_incl_vat', 'Amount (inc. vat)')
                            : t('total_excl_vat', 'Amount (ex. vat)')
                    }
                    value={cart?.totalInclVat ?? cart?.totalExVat}
                    usePoints={usePoints}
                />
            </CostRows>
        </Wrapper>
    );
};

type CostRowsProps = {
    total?: string | null;
    children?: JSX.Element[] | JSX.Element;
    pending?: boolean;
    usePoints: boolean;
};
export const CostRows = ({ total, children, pending, usePoints }: CostRowsProps) => {
    const { t } = useTranslation();
    return (
        <>
            {total && (
                <Amount justify="between" className="f1-500 mb-1">
                    <Label className="f1-500">{t('total', 'Amount')}</Label>
                    <Sum className="f1-700">
                        {pending ? <Skeleton width={90} /> : <DisplayPrice price={total} usePoints={usePoints} />}
                    </Sum>
                </Amount>
            )}
            {children}
        </>
    );
};

type CostRowProps = {
    label: string;
    value?: string | null;
    pending?: boolean;
    usePoints: boolean;
};
export const CostRow: React.FC<CostRowProps> = ({ label, value, pending, usePoints }) =>
    value ? (
        <StyledCostRow justify="between">
            <Label>{label}</Label>
            <Cost>{pending ? <Skeleton width={50} /> : <DisplayPrice price={value} usePoints={usePoints} />}</Cost>
        </StyledCostRow>
    ) : null;

export default Total;

const Wrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.fillWhite};
`;

const Amount = styled(Flex)`
    font-size: 18px;
`;

const Label = styled.span``;

const Sum = styled.span`
    color: ${({ theme }) => theme.colors.sale};
`;

const StyledCostRow = styled(Flex)`
    flex-grow: 1;
    font-size: 12px;
`;

const Cost = styled.span``;
