"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateProfileModelToJSON = exports.UpdateProfileModelFromJSONTyped = exports.UpdateProfileModelFromJSON = exports.instanceOfUpdateProfileModel = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the UpdateProfileModel interface.
 */
function instanceOfUpdateProfileModel(value) {
    var isInstance = true;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    return isInstance;
}
exports.instanceOfUpdateProfileModel = instanceOfUpdateProfileModel;
function UpdateProfileModelFromJSON(json) {
    return UpdateProfileModelFromJSONTyped(json, false);
}
exports.UpdateProfileModelFromJSON = UpdateProfileModelFromJSON;
function UpdateProfileModelFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'phoneNumber': !(0, runtime_1.exists)(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
    };
}
exports.UpdateProfileModelFromJSONTyped = UpdateProfileModelFromJSONTyped;
function UpdateProfileModelToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'phoneNumber': value.phoneNumber,
        'firstName': value.firstName,
        'lastName': value.lastName,
    };
}
exports.UpdateProfileModelToJSON = UpdateProfileModelToJSON;
