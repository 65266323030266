import useAppSelector from 'hooks/useAppSelector';
import Text from 'components/ui/Text';
import { resetPassword, selectSelectedShopUnitInfo } from 'store/reducers/userSlice';
import styled from 'styled-components/macro';
import useAppDispatch from 'hooks/useAppDispatch';
import { alertQueued } from '../../store/reducers/alertSLice';
import { useTranslation } from 'react-i18next';
import { selectUserInfo } from 'store/reducers/shopSlice';

export const Customer = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const userIdentity = useAppSelector((state) => state.user.userIdentity);
    const isAuthenticated = useAppSelector(selectUserInfo);

    if (!isAuthenticated) return null;

    return (
        <div className="w-100 px-3 mx-0 my-2">
            <h2>{t('customerData')}</h2>
            <Section>
                <Text bold>
                    {t('email')}/ {t('user')}
                </Text>
                <Text>{userIdentity?.user?.email}</Text>
                <TextButton
                    underline
                    onClick={() =>
                        dispatch(
                            alertQueued({
                                cancelLabel: t('cancel'),
                                okLabel: t('ok'),
                                title: t('reset_password'),
                                description: t('reset_password_confirmation'),
                                onOk: () => dispatch(resetPassword()),
                                onCancel: () => {},
                            }),
                        )
                    }
                >
                    {t('reset_password')}
                </TextButton>
            </Section>
        </div>
    );
};

const Section = styled.div`
    margin-bottom: 20px;
    margin-top: 20px;
`;

const TextButton = styled(Text)`
    cursor: pointer;
`;
