"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopThemeToJSON = exports.ShopThemeFromJSONTyped = exports.ShopThemeFromJSON = exports.instanceOfShopTheme = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the ShopTheme interface.
 */
function instanceOfShopTheme(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfShopTheme = instanceOfShopTheme;
function ShopThemeFromJSON(json) {
    return ShopThemeFromJSONTyped(json, false);
}
exports.ShopThemeFromJSON = ShopThemeFromJSON;
function ShopThemeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'mainBackgroundColor': !(0, runtime_1.exists)(json, 'mainBackgroundColor') ? undefined : json['mainBackgroundColor'],
        'mainTextColor': !(0, runtime_1.exists)(json, 'mainTextColor') ? undefined : json['mainTextColor'],
        'footerBackgroundColor': !(0, runtime_1.exists)(json, 'footerBackgroundColor') ? undefined : json['footerBackgroundColor'],
        'footerTextColor': !(0, runtime_1.exists)(json, 'footerTextColor') ? undefined : json['footerTextColor'],
        'accentBackgroundColor': !(0, runtime_1.exists)(json, 'accentBackgroundColor') ? undefined : json['accentBackgroundColor'],
        'accentTextColor': !(0, runtime_1.exists)(json, 'accentTextColor') ? undefined : json['accentTextColor'],
        'accentSecondaryBackgroundColor': !(0, runtime_1.exists)(json, 'accentSecondaryBackgroundColor') ? undefined : json['accentSecondaryBackgroundColor'],
        'accentSecondaryTextColor': !(0, runtime_1.exists)(json, 'accentSecondaryTextColor') ? undefined : json['accentSecondaryTextColor'],
        'lightness': !(0, runtime_1.exists)(json, 'lightness') ? undefined : json['lightness'],
        'fontBody': !(0, runtime_1.exists)(json, 'fontBody') ? undefined : json['fontBody'],
        'fontHeader': !(0, runtime_1.exists)(json, 'fontHeader') ? undefined : json['fontHeader'],
    };
}
exports.ShopThemeFromJSONTyped = ShopThemeFromJSONTyped;
function ShopThemeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'mainBackgroundColor': value.mainBackgroundColor,
        'mainTextColor': value.mainTextColor,
        'footerBackgroundColor': value.footerBackgroundColor,
        'footerTextColor': value.footerTextColor,
        'accentBackgroundColor': value.accentBackgroundColor,
        'accentTextColor': value.accentTextColor,
        'accentSecondaryBackgroundColor': value.accentSecondaryBackgroundColor,
        'accentSecondaryTextColor': value.accentSecondaryTextColor,
        'lightness': value.lightness,
        'fontBody': value.fontBody,
        'fontHeader': value.fontHeader,
    };
}
exports.ShopThemeToJSON = ShopThemeToJSON;
