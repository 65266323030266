import useAppSelector from 'hooks/useAppSelector';
import styled from 'styled-components';
import { Option } from 'types/Option';
import Select from '../../components/ui/Select';
import { selectShopUnits } from 'store/reducers/userSlice';
import { selectSelectedShopUnit } from 'store/reducers/shopSlice';
import { ShopUnitUserInfo } from 'microshop-api';
import { setShopUnit } from 'services/api';

const UserCustomerCodeOptions = () => {
    const shopUnits = useAppSelector(selectShopUnits);
    const selectedShopUnit = useAppSelector(selectSelectedShopUnit);
    const { shop } = useAppSelector(({ shop }) => shop);

    const shopUnitOptions = shopUnits?.map<Option<string>>((shopUnit) => ({
        value: shopUnit.shopUnitId!,
        label: `${shopUnit.shopUnitName ?? shop?.name}`,
    }));

    const swapShopUnit = (shopUnit: ShopUnitUserInfo) => {
        const shopUnitId = shopUnit.shopUnitId?.toString() || '';
        localStorage.setItem('shopUnitId_' + window.location.hostname, shopUnitId);
        setShopUnit(shopUnitId);
        window.location.replace(window.location.origin + '/');
    };

    if (!!shopUnitOptions?.length && shopUnitOptions?.length > 1)
        return (
            <CustomerCodeWrapper>
                <Select
                    tiny
                    isSearchable={false}
                    isMulti={false}
                    value={shopUnitOptions.find((c) => c.value === selectedShopUnit?.id)}
                    options={shopUnitOptions}
                    onChange={(option) => {
                        shopUnits && option && swapShopUnit(shopUnits.find((c) => c.shopUnitId === option.value)!);
                    }}
                />
            </CustomerCodeWrapper>
        );

    if (!!shopUnitOptions?.length && shopUnitOptions?.length === 1) {
        return <CustomerCodeWrapper>{shopUnitOptions[0].label}</CustomerCodeWrapper>;
    }

    return <>{selectedShopUnit?.name}</>;
};

export default UserCustomerCodeOptions;

const CustomerCodeWrapper = styled.div`
    color: ${({ theme }) =>
        theme.colors.text}; // Dropdown is inheriting the color of the bar so we reset it to text here
`;
