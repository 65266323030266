"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopDeliveryAddressToJSON = exports.ShopDeliveryAddressFromJSONTyped = exports.ShopDeliveryAddressFromJSON = exports.instanceOfShopDeliveryAddress = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the ShopDeliveryAddress interface.
 */
function instanceOfShopDeliveryAddress(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfShopDeliveryAddress = instanceOfShopDeliveryAddress;
function ShopDeliveryAddressFromJSON(json) {
    return ShopDeliveryAddressFromJSONTyped(json, false);
}
exports.ShopDeliveryAddressFromJSON = ShopDeliveryAddressFromJSON;
function ShopDeliveryAddressFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'address1': !(0, runtime_1.exists)(json, 'address1') ? undefined : json['address1'],
        'address2': !(0, runtime_1.exists)(json, 'address2') ? undefined : json['address2'],
        'postCode': !(0, runtime_1.exists)(json, 'postCode') ? undefined : json['postCode'],
        'city': !(0, runtime_1.exists)(json, 'city') ? undefined : json['city'],
        'country': !(0, runtime_1.exists)(json, 'country') ? undefined : json['country'],
    };
}
exports.ShopDeliveryAddressFromJSONTyped = ShopDeliveryAddressFromJSONTyped;
function ShopDeliveryAddressToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'address1': value.address1,
        'address2': value.address2,
        'postCode': value.postCode,
        'city': value.city,
        'country': value.country,
    };
}
exports.ShopDeliveryAddressToJSON = ShopDeliveryAddressToJSON;
