import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { Identity, ShopUser, ShopUserGetRequest, ShopUserPageInfo, ShopUserType } from 'microshop-api';
import services from 'services';
import { RootState } from 'store';
import { selectSelectedShopUnit } from './shopSlice';
import IconToast from 'components/ui/IconToast';
import { toast } from 'react-toastify';
import i18n from 'i18n';
import { updateShopUnitIdIfInvalid } from 'services/api';

type UserState = {
    pending: boolean;
    userIdentity?: Identity | null;
    // priceViewSetting: PriceViewSetting;
    // priceLists: CustomerPriceList[];
    // customerUsers: {
    //     rejected?: string;
    //     loading: boolean;
    //     users: UserInfo[];
    // };
    // orderSubscriptions: {
    //     rejected?: boolean;
    //     loading: boolean;
    //     subscriptions: WebOrderSubscription[];
    // };
    // successfullyAuthenticated: boolean;
    // sellingPending: boolean;
    pagedShopUsers: ShopUserPageInfo | null;
    pagedShopUsersLoading: boolean;
    searchUsers: ShopUserGetRequest;
    selectedShopUser?: string;
    selectedShopUserDataUpdated?: ShopUser;
};

const initialState: UserState = {
    pending: false,
    // successfullyAuthenticated: false,
    // priceViewSetting: PriceViewSetting.Retail,
    // priceLists: [],
    // customerUsers: {
    //     loading: false,
    //     users: [],
    // },
    // orderSubscriptions: {
    //     loading: false,
    //     subscriptions: [],
    // },
    // sellingPending: false,

    pagedShopUsers: null,
    pagedShopUsersLoading: false,
    searchUsers: {
        page: 1,
        pageSize: 10,
    },
    selectedShopUser: undefined,
    selectedShopUserDataUpdated: undefined,
};

// search: {
//     from: createDate(0, -6),
//     to: createDate(0),
//     page: 1,
//     pageSize: 10,
// },

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        searchUsersSet: (state, action) => {
            state.searchUsers = action.payload;
        },
        selectedShopUserSet: (state, action) => {
            state.selectedShopUser = action.payload;

            if (action.payload !== null && action.payload !== undefined) {
                state.selectedShopUserDataUpdated = state.pagedShopUsers?.items?.find(
                    (shopUser) => shopUser.email === action.payload.toString(),
                );
            }

            if (action.payload === null) {
                state.selectedShopUserDataUpdated = {
                    email: null,
                    firstName: null,
                    lastName: null,
                    phoneNumber: null,
                    role: ShopUserType.User,
                    adressLine1: null,
                    adressLine2: null,
                    city: null,
                    postalCode: null,
                    country: null,
                    employeeNumber: null,
                    isAttestent: false,
                    requiresAttestation: false,
                };
            }
        },
        selectedShopUserDataUpdatedSet: (state, action) => {
            state.selectedShopUserDataUpdated = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadCustomer.pending, (state) => {
            state.pending = true;
        });
        builder.addCase(loadCustomer.rejected, (state) => {
            state.pending = false;
        });
        builder.addCase(loadCustomer.fulfilled, (state, action) => {
            state.pending = false;
            state.userIdentity = action.payload;
        });
        builder.addCase(getShopUsers.pending, (state) => {
            state.pagedShopUsersLoading = true;
        });
        builder.addCase(getShopUsers.fulfilled, (state, action) => {
            state.pagedShopUsersLoading = false;
            state.pagedShopUsers = action.payload;
        });
    },
});

export const getShopUsers = createAsyncThunk<
    ShopUserPageInfo,
    void,
    {
        state: RootState;
    }
>('user/shopUsers', async (_, { getState, rejectWithValue }) => {
    const state = getState().user;

    const request = state.searchUsers;

    const result = await services.shopUser.shopUserGet(request);
    return result;
});

export const upsertShopUser = createAsyncThunk<
    void,
    void,
    {
        state: RootState;
    }
>('users/upsertShopUser', async (_, { getState, rejectWithValue, dispatch }) => {
    const state = getState();

    const request = state.user.selectedShopUserDataUpdated;

    await services.shopUser.shopUserPost({ shopUser: request });
    toast(() => <IconToast preset="success" text={i18n.t('Sparad')} />, {
        autoClose: 2000,
    });
    dispatch(getShopUsers());
});

export const deleteShopUser = createAsyncThunk<
    void,
    string,
    {
        state: RootState;
    }
>('users/deleteShopUser', async (email, { getState, rejectWithValue, dispatch }) => {
    await services.shopUser.shopUserDelete(email);
    dispatch(getShopUsers());
});

export const loadCustomer = createAsyncThunk<
    Identity | null,
    void,
    {
        state: RootState;
    }
>('user/customer', async (_, { getState, rejectWithValue }) => {
    // const isB2BShop = getState().shop.shop?.isB2BShop;

    try {
        // if (isB2BShop) {
        const result = await services.customer.customerGet();
        const shopUnitIds = result?.shopUnits?.map((unit) => unit.shopUnitId?.toString() ?? '') || [];

        updateShopUnitIdIfInvalid(shopUnitIds);

        return result;
        // }
        // return null;
    } catch (e) {
        return rejectWithValue({ error: 'An exception occured when loading the customer' });
    }
});

export const resetPassword = createAsyncThunk<
    boolean,
    string | undefined,
    {
        state: RootState;
    }
>('user/resetpassword', async (email, { getState, rejectWithValue, dispatch }) => {
    const state = getState();
    const userEmail = state.user.userIdentity?.user?.email;

    if (!userEmail) {
        return false;
    }

    const result = await services.user.resetPassword(userEmail);
    return result;
});

export const updateProfile = createAsyncThunk<
    void,
    { firstName: string; lastName: string; phoneNumber?: string | null },
    {
        state: RootState;
    }
>('user/updateprofile', async (inputModel, { rejectWithValue }) => {
    await services.user.updateProfile(inputModel);
});

// Selectors
export const selectShopUnits = (state: RootState) => state.user.userIdentity?.shopUnits;
export const selectShopUsers = (state: RootState) => state.user.pagedShopUsers?.items;
export const selectPagedShopUsers = (state: RootState) => state.user.pagedShopUsers;
export const selectsearchUsers = (state: RootState) => state.user.searchUsers;
export const selectSelectedShopUser = (state: RootState) => state.user.selectedShopUser;
export const selectSelectedShopUserDataUpdated = (state: RootState) => state.user.selectedShopUserDataUpdated;
export const selectPagedShopUsersLoading = (state: RootState) => state.user.pagedShopUsersLoading;

export const selectselectedShopUserData = createSelector(
    selectShopUsers,
    selectSelectedShopUser,
    (shopUsers, selectedShopUser) => {
        return shopUsers?.find((shopUser) => shopUser.email === selectedShopUser);
    },
);

export const selectSelectedShopUnitInfo = createSelector(
    selectShopUnits,
    selectSelectedShopUnit,
    (shopUnits, selectedShopUnit) => {
        return shopUnits?.find((shopUnit) => shopUnit.shopUnitId === selectedShopUnit?.id);
    },
);

export const selectIsAttestant = createSelector(selectSelectedShopUnitInfo, (selectedShopUnitInfo) => {
    return selectedShopUnitInfo?.isAttestent;
});
export const selectIsShopManager = createSelector(selectSelectedShopUnitInfo, (selectedShopUnitInfo) => {
    return selectedShopUnitInfo?.role === ShopUserType.ShopManager;
});

export const { searchUsersSet, selectedShopUserSet, selectedShopUserDataUpdatedSet } = userSlice.actions;

export default userSlice.reducer;
