import { faCoins } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/ui/Flex';
import styled from 'styled-components';

type PriceValue = {
    price: string | number | null | undefined;
    usePoints: boolean;
};
export const DisplayPrice = ({ price, usePoints }: PriceValue) =>
    usePoints ? (
        <div>
            <Label>
                <StyledFontAwesomeIcon icon={faCoins} />
            </Label>
            <Cost>{price}</Cost>
        </div>
    ) : (
        <Cost>{price}</Cost>
    );

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    padding-right: 5px;
`;

const Label = styled.span``;

const Cost = styled.span``;
