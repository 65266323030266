import { ShopUserApi, ShopUserGetRequest, ShopUserUpsertPOSTRequest } from 'microshop-api';
import { config } from './api';

const shopUserApi = new ShopUserApi(config);

const shopUser = {
    shopUserGet: (request: ShopUserGetRequest) => shopUserApi.shopUserGet(request),
    shopUserPost: (request: ShopUserUpsertPOSTRequest) => shopUserApi.shopUserUpsertPOST(request),
    shopUserDelete: (email: string) => shopUserApi.shopUserDeleteMicroshopShopUserPOST({ email }),
};

export { shopUser };
